/**
 * @export Insights 文章
 * @author 林良伟
 * @Created 2022/6/10.
 */
export default {
    // insights 文章
    insightsArticleNewArticle: {
        // insights 新文章
        path: '/insights-article/new-article',
        name: 'insightsArticleNewArticle',
        component: () => import('@/pages/modules/insights-article/new-article/index.vue'),
    },
    insightsArticleArticleList: {
        // insights 文章列表
        path: '/insights-article/article-list',
        name: 'insightsArticleArticleList',
        component: () => import('@/pages/modules/insights-article/article-list/index.vue'),
    },
    periodMode: {
        // 经期模式推荐配置
        path: '/insights-article/period-mode',
        name: 'periodMode',
        component: () => import('@/pages/modules/insights-article/period-mode/index.vue'),
    },
    prePregnancyMode: {
        // 备孕模式推荐配置
        path: '/insights-article/pre-pregnancy-mode',
        name: 'prePregnancyMode',
        component: () => import('@/pages/modules/insights-article/pre-pregnancy-mode/index.vue'),
    },
    pregnancyMode: {
        // 孕期模式推荐配置
        path: '/insights-article/pregnancy-mode',
        name: 'pregnancyMode',
        component: () => import('@/pages/modules/insights-article/pregnancy-mode/index.vue'),
    }
};
