/**
 * @export 页面配置
 * @author 林良伟
 * @Created 2022/6/13.
 */
export default {
    pageConfigPregnancyArticleNewArticle: {
        // 孕期首页-新文章
        path: '/pregnancy-article/new-article',
        name: 'pageConfigPregnancyArticleNewArticle',
        component: () => import('@/pages/modules/pregnancy-article/new-article/index.vue'),
    },
    pageConfigPregnancyArticleArticleList: {
        // 孕期首页-文章列表
        path: '/pregnancy-article/article-list',
        name: 'pageConfigPregnancyArticleArticleList',
        component: () => import('@/pages/modules/pregnancy-article/article-list/index.vue'),
    }
};
