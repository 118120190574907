import type { App } from 'vue';
import { createI18n } from 'vue-i18n';
import type { I18n, I18nOptions } from 'vue-i18n';
import { useBaseStore } from '../store/modules/base';

export let i18n: ReturnType<typeof createI18n>;

const langModules = {};
const modulesFiles = import.meta.glob('./modules/*.ts', { eager: true });

Object.keys(modulesFiles).forEach(path => {
    const moduleName = path.replace(/(.*\/)*([^.]+).*/gi, '$2');
    const modulesItem = modulesFiles[path] as any;
    Object.keys(modulesItem).forEach(modulesLangKey => {
        langModules[modulesLangKey] = {
            ...langModules[modulesLangKey],
            [moduleName]: modulesItem[modulesLangKey],
        };
    });
});

async function createI18nOptions(): Promise<I18nOptions> {
    const baseStore = useBaseStore();
    let lang: string = baseStore.getLanguage;
    if (lang === 'in') lang = 'ind';
    return {
        legacy: false, // 必须设置 false 才能使用 Composition API
        globalInjection: true, // 使用全局$t()函数
        locale: lang,
        fallbackLocale: 'en', // 找不到语言模块时默认
        messages: langModules,
    };
}

// setup i18n instance with glob
export async function setupI18n(app: App) {
    const options = await createI18nOptions();
    // @ts-ignore
    i18n = createI18n(options) as I18n;
    app.use(i18n);
}
