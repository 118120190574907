export const zh = {
    hotManagement: 'Hot 内容运营',
    positionSetup: '运营位设置',
    hotList: '运营位列表',
    operateNumber: '运营位编号',
    forcedRefresh: '强制刷新',
    hottedList: '运营位已展示列表',
    uv: '观看人数',
    like: '点赞数',
    comment: '评论数',
    displayed: '已展示',
    postDisplayed: 'Post 已展示',
    toBeDisplayed: '待展示',
    displayIng: '展示中',
    postDisplayIng: 'Post 展示中',
    uvMin: '最低观看人数',
    areYouSureToClearThePosition: '确定要清空该运营位？',
    theSecondRefreshTimeIsMinutesLater: '第二次可刷新时间为 {time} 分钟后',
    add: '添加',
    edit: '编辑',
    expired: '失效',
};
export const en = {
    hotManagement: 'Hot Management',
    positionSetup: 'Position Setup',
    hotList: 'Hot List',
    operateNumber: 'Number',
    forcedRefresh: 'Forced Refresh',
    hottedList: 'Hotted List',
    uv: 'UV',
    like: 'Like',
    comment: 'Comment',
    displayed: 'Displayed',
    postDisplayed: 'Post Displayed',
    toBeDisplayed: 'To be displayed',
    postDisplayIng: 'Post On display',
    uvMin: 'UV-min',
    areYouSureToClearThePosition: 'Are you sure to clear the position?',
    theSecondRefreshTimeIsMinutesLater: 'The second refresh time is {time} minutes later',
    add: 'Add',
    edit: 'Edit',
    expired: 'Expired',
};

export const ar = {
    hotManagement: 'Hot Management',
    positionSetup: 'Position Setup',
    hotList: 'Hot List',
    operateNumber: 'Number',
    forcedRefresh: 'Forced Refresh',
    hottedList: 'Hotted List',
    uv: 'UV',
    like: 'Like',
    comment: 'Comment',
    displayed: 'Displayed',
    postDisplayed: 'Post Displayed',
    toBeDisplayed: 'To be displayed',
    postDisplayIng: 'Post On display',
    uvMin: 'UV-min',
    areYouSureToClearThePosition: 'Are you sure to clear the position?',
    theSecondRefreshTimeIsMinutesLater: 'The second refresh time is {time} minutes later',
    add: 'إضافة ',
    edit: 'تحرير',
    expired: 'انتهاء الصلاحية',
}
