import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import qs from 'qs';
import type { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import type { Result, UploadFileParams } from '@/types/axios';
import { useBaseStore } from '@/store/modules/base';
import { CreateAxiosOptions, headersTs, requestOptionsTs } from './types';
import requestFailed from './requestFailed';

const baseStore = useBaseStore();

/**
 * @description:  axios module
 */
export class VAxios {
    private axiosInstance: AxiosInstance;
    private readonly options: CreateAxiosOptions;

    constructor(options: CreateAxiosOptions) {
        this.options = options;
        this.axiosInstance = axios.create(options);
        this.setupInterceptors();
    }

    private setupInterceptors() {
        // 请求拦截器
        this.axiosInstance.interceptors.request.use(config => {
            const configParams = config as CreateAxiosOptions;
            const customHeaders = {} as headersTs;
            const contentType = configParams.requestOptions?.contentType;
            configParams.requestOptions.tableLoading !== false && baseStore.setTableLoading(true);
            customHeaders['Content-Type'] = 'application/json; charset=utf-8';
            if (contentType === 'form') {
                customHeaders['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
                configParams.data = qs.stringify(config.data, { arrayFormat: 'brackets' });
            }
            customHeaders.deviceId = 'ADMIN';
            customHeaders.Accept = 'application/json, text/plain, */*';
            customHeaders.Authorization = baseStore.getToken || '';
            customHeaders.language = baseStore.language;
            customHeaders.userAgent = 'webWocuteAdmin';
            if (configParams.headers) {
                configParams.headers = Object.assign(customHeaders, configParams.headers);
            } else {
                configParams.headers = customHeaders;
            }
            return configParams;
        });
        // 响应拦截器
        this.axiosInstance.interceptors.response.use(
            res => {
                const configParams = res.config as CreateAxiosOptions;
                configParams.requestOptions.tableLoading !== false && baseStore.setTableLoading(false);
                // 判断是arraybuffer类型时放行下载
                if(res.config.responseType === 'arraybuffer'){
                    return res.data
                }
                if (res.status === 200 && res.data.code === 200) {
                    return res.data;
                }
                return requestFailed(res);
            },
            error => requestFailed(error)
        );
    }

    request<T = any>(config: AxiosRequestConfig, options: requestOptionsTs | undefined): Promise<T> {
        const conf = cloneDeep(config) as CreateAxiosOptions;
        const { requestOptions } = this.options;
        const opts: requestOptionsTs = Object.assign({}, requestOptions, options);
        conf.requestOptions = opts;
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .request<any, AxiosResponse<Result>>(conf)
                .then((res: AxiosResponse<Result>) => {
                    try {
                        resolve(res as any);
                    } catch (err) {
                        reject(err || new Error('request error!'));
                    }
                })
                .catch((ev: Error | AxiosError) => {
                    reject(ev);
                    if (axios.isAxiosError(ev)) {
                        // 在此处重写来自axios的错误消息
                    }
                });
        });
    }

    get<T = any>(config: AxiosRequestConfig, options?: requestOptionsTs): Promise<T> {
        return this.request({ ...config, method: 'GET' }, options);
    }

    post<T = any>(config: AxiosRequestConfig, options?: requestOptionsTs): Promise<T> {
        return this.request({ ...config, method: 'POST' }, options);
    }

    put<T = any>(config: AxiosRequestConfig, options?: requestOptionsTs): Promise<T> {
        return this.request({ ...config, method: 'PUT' }, options);
    }

    delete<T = any>(config: AxiosRequestConfig, options?: requestOptionsTs): Promise<T> {
        return this.request({ ...config, method: 'DELETE' }, options);
    }
}
