/**
 * @export 内容审核
 * */
export default {
    contentReviewWarmthExamine: {
        // warmth审核
        path: '/content-review/warmth-examine',
        name: 'contentReviewWarmthExamine',
        component: () => import('@/pages/modules/content-review/warmth-examine/index.vue'),
        meta: { pageLang: ['zh', 'en', 'ar'] },
    },
    contentReviewWorkOrder: {
        // 工单页面
        path: '/content-review/work-order',
        name: 'contentReviewWorkOrder',
        component: () => import('@/pages/modules/content-review/work-order/index.vue'),
        meta: { pageLang: ['zh', 'en', 'ar'] },
    },
    deviceManage: {
        // 设备管理
        path: '/content-review/device-manage',
        name: 'contentReviewDeviceManage',
        component: () => import('@/pages/modules/content-review/device-manage/index.vue'),
        meta: { pageLang: ['zh', 'en', 'ar'] },
    },
    contentReviewBanManage: {
        // 禁言管理
        path: '/content-review/ban-manage',
        name: 'contentReviewBanManage',
        component: () => import('@/pages/modules/content-review/ban-manage/index.vue'),
    }
};
