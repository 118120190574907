/**
 * @export 工具内容推荐
 * @author 林良伟
 * @Created 2022/10/14.
 */
export default {
    contentRecommendMenstruation: {
        // 经期模式
        path: '/content-recommend/menstruation',
        name: 'contentRecommendMenstruation',
        component: () => import('@/pages/modules/content-recommend/menstruation/index.vue'),
    },
    contentRecommendPreparePregnant: {
        // 备孕模式
        path: '/content-recommend/prepare-pregnant',
        name: 'contentRecommendPreparePregnant',
        component: () => import('@/pages/modules/content-recommend/prepare-pregnant/index.vue'),
    },
    contentRecommendPregnancy: {
        // 孕期模式
        path: '/content-recommend/pregnancy',
        name: 'contentRecommendPregnancy',
        component: () => import('@/pages/modules/content-recommend/pregnancy/index.vue'),
    },
    contentRecommendExplore: {
        // Explore页推荐位
        path: '/content-recommend/explore',
        name: 'contentRecommendExplore',
        component: () => import('@/pages/modules/content-recommend/explore/index.vue'),
    },
    contentPrizeConTest: {
        // 内容奖品竞赛
        path: '/content-recommend/prize-con-test',
        name: 'contentPrizeConTest',
        component: () => import('@/pages/modules/content-recommend/prize-con-test/index.vue'),
    }
};
