import { createApp } from 'vue';
import App from './App.vue';
import 'ant-design-vue/dist/antd.less';
// import Antd from 'ant-design-vue';

import { setupStore } from './store';
import { setupI18n } from './lang/setupI18n';
import router from './router';

const app = createApp(App);

setupStore(app);
setupI18n(app).then();
app.use(router);
// app.use(Antd);

app.mount('#app');
