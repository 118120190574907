/**
 * @author 林良伟
 * @export 权限管理
 * @Created 2023/4/4.
 */
import { defHttp } from '@/http/axios';

enum Api {
    AUTH_LIST_TO_TREE = '/services/admin/api/auth/listToTree', // 获取权限列表
}

/**
 * @function 获取全部菜单列表
 */
export const allMenuListApi = () => defHttp.post({ url: Api.AUTH_LIST_TO_TREE });
