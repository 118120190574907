export const zh = {
    woCuteAdmin: 'Wocute 管理后台',
    WelcomeToWoCuteAdmin: '欢迎光临，Wocute管理后台！',
    logIn: '登录',
    exitSystem: '退出系统',
    accountLogin: '账号登录',
    dingDingLogIn: '钉钉登录',
    rememberMe: '记住账号',
    pleaseInputYourUsername: '请输入你的用户名！',
    pleaseInputYourPassword: '请输入你的密码！',
    userIDOrMicroBlogID: '可搜索用户ID或者帖子ID',
    pleaseInputMicroBlogId: '输入帖子id，用分号隔开',
    functionType: '功能类型',
    userType: '用户类型',
    officialUser: '官方用户',
    generalUser: '普通用户',
    fullFunction: '全功能',
    letStartYourDay: '开始您一天的工作吧！',
    externalLink: '外部链接',
    operationLocationList:'运营位列表'

};
export const en = {
    woCuteAdmin: 'Wocute Admin',
    WelcomeToWoCuteAdmin: 'Welcome WOCUTE Background Management',
    logIn: 'Login',
    exitSystem: 'Exit System',
    accountLogin: 'Account Login',
    dingDingLogIn: 'Ding Ding Log In',
    rememberMe: 'Remember Account',
    pleaseInputYourUsername: 'Please input your username!',
    pleaseInputYourPassword: 'Please input your password!',
    userIDOrMicroBlogID: 'UserID or MicroblogID',
    pleaseInputMicroBlogId: 'Please input MicroblogID and separate them with a semicolon',
    functionType: 'Function Type',
    userType: 'User Type',
    officialUser: 'Official User',
    generalUser: 'General User',
    fullFunction: 'Full Function',
    letStartYourDay: "let's start your day!",
    externalLink: 'External link',
    operationLocationList:'Operation location list'
};

export const ar = {
    woCuteAdmin: 'Wocute Admin',
    WelcomeToWoCuteAdmin: 'Welcome WOCUTE Background Management',
    logIn: 'Login',
    exitSystem: 'Exit System',
    accountLogin: 'Account Login',
    dingDingLogIn: 'Ding Ding Log In',
    rememberMe: 'Remember Account',
    pleaseInputYourUsername: 'Please input your username!',
    pleaseInputYourPassword: 'Please input your password!',
    userIDOrMicroBlogID: 'UserID or MicroblogID',
    pleaseInputMicroBlogId: 'Please input MicroblogID and separate them with a semicolon',
    functionType: 'Function Type',
    userType: 'User Type',
    officialUser: 'Official User',
    generalUser: 'General User',
    fullFunction: 'Full Function',
    letStartYourDay: "let's start your day!",
    externalLink: 'External link',
    operationLocationList:'Operation location list'
}
