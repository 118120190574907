export interface ImportMetaEnv {
    VUE_APP_BASE_API: string;
    H5_DOMAIN_NAME: string;
    PREFIX: string;
    VUE_APP_DD_APP_KEY: string;
    RESOURCE_URL: string;
    CRM_SERVICE: string;
}

const envMap = new Map([
    [
        'development',
        {
            VUE_APP_BASE_API: '/api',
            H5_DOMAIN_NAME: 'http://dev-h5.wocute.com:8981',
            PREFIX: '/to-h5',
            VUE_APP_DD_APP_KEY: 'dingoaktrcofqmbh5ijkqi',
            RESOURCE_URL: '/',
            CRM_SERVICE: 'http://dev-crm.meiqijiacheng.com',
        },
    ],
    [
        'devTest',
        {
            VUE_APP_BASE_API: '//test-gapi.wocute.com',
            H5_DOMAIN_NAME: '//test-h5.wocute.com',
            PREFIX: '/to-h5',
            VUE_APP_DD_APP_KEY: 'dingoabgvvevj5g4c5hxav',
            RESOURCE_URL: '/',
            CRM_SERVICE: 'http://dev-crm.meiqijiacheng.com',
        },
    ],
    [
        'pre',
        {
            VUE_APP_BASE_API: 'https://pre-gapi.wocute.com',
            H5_DOMAIN_NAME: 'https://pre-h5.wocute.com',
            PREFIX: '/to-h5',
            VUE_APP_DD_APP_KEY: 'dingoars5yln3n3ghinfzv',
            RESOURCE_URL: '//cdn.wocute.com/wocute-web-staging/admin/',
            CRM_SERVICE: 'https://crm.meiqijiacheng.com',
        },
    ],
    [
        'production',
        {
            VUE_APP_BASE_API: 'https://gapi.wocute.com',
            H5_DOMAIN_NAME: 'https://h5.wocute.com',
            PREFIX: '/to-h5',
            VUE_APP_DD_APP_KEY: 'dingoaq7h05mbixwefwgbc',
            RESOURCE_URL: '//cdn.wocute.com/wocute-admin-deploy/',
            CRM_SERVICE: 'https://crm.meiqijiacheng.com',
        },
    ],
]);

export const { VUE_APP_BASE_API } = envMap.get(import.meta.env.MODE) as ImportMetaEnv;
export const { H5_DOMAIN_NAME } = envMap.get(import.meta.env.MODE) as ImportMetaEnv;
export const { PREFIX } = envMap.get(import.meta.env.MODE) as ImportMetaEnv;
export const { VUE_APP_DD_APP_KEY } = envMap.get(import.meta.env.MODE) as ImportMetaEnv;
export const { RESOURCE_URL } = envMap.get(import.meta.env.MODE) as ImportMetaEnv;
export const { CRM_SERVICE } = envMap.get(import.meta.env.MODE) as ImportMetaEnv;

export default {
    VUE_APP_BASE_API,
    H5_DOMAIN_NAME,
    PREFIX,
    VUE_APP_DD_APP_KEY,
    RESOURCE_URL,
    CRM_SERVICE,
};
