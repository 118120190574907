import { VAxios } from './axios';
import { deepMerge } from '@/utils';
import ENV from '@/config/env';
import { CreateAxiosOptions } from './types';

function createAxios(options?: Partial<CreateAxiosOptions>) {
    const vAxios = new VAxios(
        deepMerge(
            {
                timeout: 1000 * 20,
                baseURL: ENV?.VUE_APP_BASE_API,
            },
            options || {}
        )
    );
    return vAxios;
}

export const defHttp = createAxios();
