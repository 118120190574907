export const zh = {
    recommendStatus: '推荐状态',
    waiting: '待生效',
    pushed: '已生效',
    closed: '已失效',
    h5JumpLink: 'H5跳转链接',
    iOSMinimumVersion: 'iOS最低版本',
    androidMinimumVersion: '安卓最低版本',
    recommendTime: '推荐时间',
    close: '下架',
};

export const en = {
    recommendStatus: 'Status',
    waiting: 'Waiting',
    pushed: 'Pushed',
    closed: 'Closed',
    h5JumpLink: 'Url',
    iOSMinimumVersion: 'iOS minimum version',
    androidMinimumVersion: 'Android minimum version',
    close: 'Close',
    recommendTime: 'Time',
};
