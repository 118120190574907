export const zh = {
    silenceStartTime: '封禁时间',
    silenceDuration: '禁言时长',
    silenceReason: '封禁原因',
    unSliEnCe: '解封',
    AreYouSureUnSliEnEnd: '确认解封?',
    silenceOneHour: '1小时',
    permanentSilence: '永久封禁',
    suspended24:'24小时',
    suspended3Day:'3天',
    suspended7Day:'7天',
    suspended30Day:'30天',
    silentUser: '增加禁言用户',
    silence: '禁言',
    voteOptions: '投票选项',
    releaseOK: '你确定要发布该帖子吗？',
    releaseOn: '你确定不发布该帖子吗？',
};
export const en = {
    silenceStartTime: 'Silence StartTime',
    silenceDuration: 'Silence Duration',
    silenceReason: 'Silence Reason',
    unSliEnCe: 'Un-slience',
    AreYouSureUnSliEnEnd: 'Are You Sure Un-slienced?',
    silenceOneHour: 'One-hour-silence',
    permanentSilence: 'Permanent Silence',
    suspended24:'suspended for 24 hour',
    suspended3Day:'suspended for 3 days',
    suspended7Day:'suspended for 7 days',
    suspended30Day:'suspended for 30 days',
    silentUser: 'Silence User',
    silence: 'Silence',
    voteOptions: 'Vote options',
    releaseOK: 'Are you sure of publishing this moment?',
    releaseOn: 'Are you sure of unpublishing this moment?',
};

export const ar = {
    silenceOneHour: 'حظر لمدة ساعة واحدة ',
    permanentSilence: 'حظر نهائيًا ',
    suspended24:'حظر لمدة 24 ساعة ',
    suspended3Day:'حظر لمدة 3 أيام ',
    suspended7Day:'حظر لمدة 7 أيام',
    suspended30Day:'حظر لمدة 30 يومًا ',
    silenceStartTime: 'Silence StartTime',
    silenceDuration: 'Silence Duration',
    silenceReason: 'Silence Reason',
    unSliEnCe: 'Un-slience',
    AreYouSureUnSliEnEnd: 'Are You Sure Un-slienced?',
    silentUser: 'Silence User',
    silence: 'Silence',
    voteOptions: 'Vote options',
    releaseOK: 'Are you sure of publishing this moment?',
    releaseOn: 'Are you sure of unpublishing this moment?',
};
