import { createRouter, createWebHistory } from 'vue-router';
import { getUserBaseRouter, routesModules } from './routes';
import { useLayoutsStore } from '@/layouts/store';
import { useBaseStore } from '@/store/modules/base';
import { setMenuKey } from '@/layouts/components/Menu/helper';

const baseStore = useBaseStore();
const layoutsStore = useLayoutsStore();

export const router = createRouter({
    history: createWebHistory(),
    routes: routesModules,
    strict: true,
    scrollBehavior: (to: any, from: any, savedPosition: any) => savedPosition || { left: 0, top: 0 },
});

const userBaseRouter = getUserBaseRouter();
router.addRoute(userBaseRouter);

router.beforeEach((to, from, next) => {
    layoutsStore.setRouterLoading(true);
    layoutsStore.getMenuConfigList.length && setMenuKey(to.path);
    to.meta.requireAuth !== false && !baseStore.getToken && loginExitInit();
    if (to.meta.title) {
        (document as any).title = to.meta.title || 'Wocute Admin';
    }
    next();
});

router.afterEach(() => {
    layoutsStore.setRouterLoading(false);
});

export default router;

// 登陆初始化
export function loginInit() {
    layoutsStore.setRemoveMenuOpenKeys();
    layoutsStore.setRemoveMenuSelectedKeys();
}

// 退出登陆初始化
export function loginExitInit() {
    router.push('/login').then();
    baseStore.setRemoveToken();
    baseStore.setRemoveUserInfo();
    layoutsStore.setRemoveMenuOpenKeys();
    layoutsStore.setRemoveMenuSelectedKeys();
}
