<template>
    <router-view v-slot="{ Component }">
        <config-provider v-if="Component" :locale="antLangModule">
            <component :is="Component" />
        </config-provider>
        <div v-if="!Component" class="full-screen-loading-box">
            <full-screen-loading :show="!Component" />
        </div>
    </router-view>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { ConfigProvider } from 'ant-design-vue';
    import { useBaseStore } from '@/store/modules/base';
    import zhCN from 'ant-design-vue/es/locale/zh_CN';
    import enUS from 'ant-design-vue/es/locale/en_US';
    import arEG from 'ant-design-vue/es/locale/ar_EG';
    import { FullScreenLoading } from '@/components/Loading';

    const baseStore = useBaseStore();

    const antLangModule = computed(() => {
        let lang = {
            zh: zhCN,
            en: enUS,
            ar: arEG,
        };
        return lang[baseStore.getLanguage];
    });
</script>

<style lang="less">
    @fontColor: #262626;
    @fontSize: 16px;

    * {
        margin: 0;
        padding: 0;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: @fontColor;
        font-size: @fontSize;
    }

    .full-screen-loading-box {
        width: 100%;
        height: 100vh;
    }
</style>
