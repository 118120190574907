/*
 * 全局缓存管理 localStorage sessionStorage cookie
 * */

/**
 * @function 设置token
 * @value 用户登录后的token值
 * */
export function setToken(value: string): void {
    if (value.length >= 32) {
        window.localStorage.setItem('appToken', value);
    }
}
// 获取token
export function getToken(): string {
    return window.localStorage.getItem('appToken') as string;
}
// 移除token
export function removeToken(): void {
    return window.localStorage.removeItem('appToken');
}

/**
 * @export StorageMessage 本地储存对象
 * @author: 邓子强
 * @Date: 2021/01/27
 */

// type ['local','session'] 存入localStorage(默认)或者sessionStorage
const getStorage = (type = 'local') => (type === 'session' ? sessionStorage : localStorage);
/**
 * @function setStorageItem 函数解释
 * @param key 设置的item的key
 * @param value 设置的item的value
 * @param type ['local','session'] 存入localStorage(默认)或者sessionStorage
 */
export const setStorageItem = (key: any, value: string | object, type = 'local'): void => {
    if (typeof value === 'object') {
        getStorage(type).setItem(key, JSON.stringify(value));
    } else {
        getStorage(type).setItem(key, `${value}`);
    }
};
export const getStorageItem = (key: any, type = 'local', json = false): any => {
    const storageData = getStorage(type).getItem(key);
    if (json && storageData && typeof storageData === 'string') return JSON.parse(storageData);
    return getStorage(type).getItem(key);
};
export const removeStorageItem = (key: any, type = 'local'): void => {
    getStorage(type).removeItem(key);
};
export const removeAllStorage = (type = 'local'): void => {
    getStorage(type).clear();
};