export const zh = {
    reportUserID: '举报人',
    reportTime: '举报时间',
    reportReason: '举报理由',
    reportDetails: '举报详情',
    advertising: '广告',
    offensive: '人身攻击',
    unscientific: '迷信',
    illegal: '违法',
    male: '男性',
    other: '其他',
    processingType: '处理类型',
    officialAccount: '显示官号',
    whiteList: '白名单',
}
export const en = {
    reportUserID: 'Report UserID',
    reportTime: 'Report Time',
    reportReason: 'Report Reason',
    reportDetails: 'Report Details',
    advertising: 'Advertising',
    offensive: 'Offensive',
    unscientific: 'Unscientific',
    illegal: 'Illegal',
    male: 'Male',
    other: 'Other',
    processingType: 'Processing type',
    officialAccount: 'Official Account',
    whiteList: 'WhiteList',
}
