export const zh = {
    addWhiteAccount: '新增白名单账号',
    editWhiteAccount: '编辑白名单账号',
    inputUserIDAndSemicolon: '请输入账号，用分号隔开',
};
export const en = {
    addWhiteAccount: 'Add WhiteList Account',
    editWhiteAccount: 'Edit WhiteList Account',
    inputUserIDAndSemicolon: 'Please input UserID and separate them with semicolon',
};
