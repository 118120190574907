/**
 * @export 数据看板
 * @author 方俊杰
 * @Created 2024/07/04.
 */

export default {
    // 帖子数据小时维度
    postDataHour: {
        name: 'postDataHour',
        path: '/data-dashboard/post-data-hour',
        component: () => import('@/pages/modules/data-dashboard/post-data-hour/index.vue'),
    },
    // 帖子数据天纬度
    postDataDay: {
        name: 'postDataDay',
        path: '/data-dashboard/post-data-day',
        component: () => import('@/pages/modules/data-dashboard/post-data-day/index.vue'),
    },
    // 用户折线图表小时纬度
    userEChartsDay: {
        name: 'userEChartsDay',
        path: '/data-dashboard/user-echarts-day',
        component: () => import('@/pages/modules/data-dashboard/user-echarts-day/index.vue'),
    }
}
