/**
 * @author 林良伟
 * @export 权限管理
 * @Created 2023/4/4.
 */
import { defHttp } from '@/http/axios';

enum Api {
    USER_AUTH_LIST = '/services/admin/api/admin/user/getAdminUserAuth', // 获取当前用户已授权的菜单列表
}

/**
 * @function 获取当前用户已授权的菜单列表
 */
export const userAuthListApi = () => defHttp.get({ url: Api.USER_AUTH_LIST });
