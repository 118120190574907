/**
 * @export 路由文件集合
 * @author 林良伟
 * @Created 2022/5/23.
 */
import { RouteRecordRaw } from 'vue-router';
import { main, otherRoutes, noLayouts } from '@/pages/base/router';
import { useLayoutsStore } from '@/layouts/store';
import { menuItem } from '@/layouts/components/Menu/types';
import { userAuthListApi } from '@/router/http';
import router from '@/router/index';

const homeMenu = [
    {
        id: '643df6dd61a1b0380422603f',
        name: {
            zh: '首页',
            en: 'home page',
            ar: 'الصفحة الأمامية',
            fr: 'page de garde',
        },
        uri: '/',
        type: 2,
        icon: 'AppstoreOutlined',
        sort: 10000,
        remark: '',
    },
];

// 本地所有路由
const allRouter = { ...main, ...otherRoutes };

export const LayoutsChildrenRoutes = [] as RouteRecordRaw[];
export const mainModules = [] as RouteRecordRaw[];
export const routesModules = [] as RouteRecordRaw[];

export const otherRoutesModules = [] as RouteRecordRaw[];

for (const key in main) {
    mainModules.push(main[key]);
}
for (const key in noLayouts) {
    routesModules.push(noLayouts[key]);
}
for (const key in otherRoutes) {
    otherRoutesModules.push(otherRoutes[key]);
}

export const Layouts = {
    path: '/',
    name: 'Layouts',
    component: () => import('@/layouts/page/index.vue'),
    meta: { title: 'Wocute Admin' },
    children: LayoutsChildrenRoutes,
};

function configRoutes(list) {
    const newList = JSON.parse(JSON.stringify(list));
    const userRouterKey = [] as string[]; // 用户的权限路由key
    const userRouter = [] as RouteRecordRaw[]; // 用户可访问的路由
    const routerModulesFiles = import.meta.glob('@/pages/modules/*/router.ts', { eager: true });
    Object.keys(routerModulesFiles).forEach(path => {
        const modulesItem = routerModulesFiles[path] as any;
        for (const key in modulesItem.default) {
            allRouter[key] = modulesItem.default[key];
        }
    });
    newList.map(item => {
        const items = item as menuItem;
        const itemKey = items.uri;
        const itemRouter = allRouter[itemKey];
        if (itemRouter) {
            items.uri = itemRouter.path;
            userRouterKey.push(itemKey);
            userRouter.push(itemRouter);
        }
    });
    useLayoutsStore().setAuthMenuList([...homeMenu, ...newList]);
    const configRoutes = [...userRouter, ...mainModules, ...otherRoutesModules];
    return configRoutes;
}

export function getUserBaseRouter() {
    const getUserRoutes = useLayoutsStore().getUserRoutes;
    const getConfigRoutes = configRoutes(getUserRoutes);
    Layouts.children = getConfigRoutes;
    return Layouts;
}

export function setUserConfigRouter() {
    userAuthListApi()
        .then(res => {
            const list = res.data?.auths || [];
            useLayoutsStore().setUserRoutes(list);
            const getConfigRoutes = configRoutes(list);
            Layouts.children = getConfigRoutes;
            router.addRoute(Layouts);
        })
        .catch(() => {
            configRoutes([]);
        });
}
