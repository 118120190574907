/**
 * @export 活动支持
 * @author 林良伟
 * @Created 2023/2/14.
 */
export default {
    activitySupportPromotionBit: {
        // 推广位
        path: '/activity-support/promotion-bit',
        name: 'activitySupportPromotionBit',
        component: () => import('@/pages/modules/activity-support/promotion-bit/index.vue'),
    },
};
