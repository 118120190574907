export const zh = {
    language: '语言',
    arabicLanguage: '阿拉伯语',
    englishLanguage: '英语',
    frenchLanguage: '法语',
    inLanguage: '印尼',
    middleEast: '中东',
    middleEastMorocco: '中东 & 摩洛哥',
    malay: '马来',
    indonesia: '印尼',
    arabic: '阿语',
    malayLanguage: '马来语',
    indonesian: '印尼语',
    englishTitle: '英文标题',
    arabicTitle: '阿语标题',
    frenchTitle: '法语标题',
    inTitle: '印尼标题',
    userId: '用户ID',
    translate: '翻译',
    okText: '确定',
    confirm: '确认',
    cancelText: '取消',
    add: '添加',
    newlyAdded: '新增',
    create: '创建',
    modify: '修改',
    closeText: '关闭',
    Ignore: '忽略',
    areYouSureIgnore: '确认忽略？',
    loadingText: '加载中...',
    delText: '删除',
    areYouSureDelete: '确认删除？',
    resetText: '重置',
    searchText: '搜索',
    queryText: '查询',
    pleaseInput: '请输入',
    pleaseChoose: '请选择',
    pleaseAdd: '请添加',
    back: '返回',
    number: '序号',
    numbers: '编号',
    userID: '用户ID',
    content: '内容',
    time: '时间',
    createTime: '创建时间',
    operation: '操作',
    handle: '处理',
    comments: '评论',
    viewComments: '查看评论',
    done: '已处理',
    unDone: '未处理',
    operator: '操作人',
    selectTime: '选择时间',
    pleaseSelectTime: '请选择时间',
    redoSuccessful: '刷新成功！',
    operationSuccessful: '操作成功！',
    newlyAddedSuccessful: '新增成功！',
    saveSuccessful: '保存成功！',
    createSuccessful: '创建成功！',
    modifySuccessful: '修改成功！',
    deleteSuccessful: '删除成功！',
    updateSuccessful: '更新成功！',
    dislodgeSuccessful: '去除成功！',
    update: '更新',
    save: '保存',
    dislodge: '去除',
    nextPage: '下一页',
    previousPage: '上一页',
    noMicroBlog: '暂无动态数据',
    noComment: '暂无评论数据',
    pleaseInputUserId: '请输入用户id',
    pleaseInputUserType: '请输入用户类型',
    total: '共',
    items: '条',
    filter: '筛选',
    yes: '是',
    no: '否',
    exportExcel: '导出Excel',
    valid: '生效中',
    invalid: '不生效',
    state: '状态',
    uploadImage: '上传图片',
    image: '图片',
    post: '发布',
    upload: '上传...',
    coverPicture: '封面图',
    coverImage: '封面图',
    pleaseUploadCoverImage: '请上传封面图',
    empty: '（空）',
    deleteReason: '删除原因',
    startingTime: '开始时间',
    endTime: '结束时间',
    operatingTime: '操作时间',
    deleteTime: '删除时间',
    expirationTime: '失效时间',
    all: '全部',
    lastModifiedBy: '最后修改人',
    lastModifiedTime: '最后修改时间',
    name: '名称',
    weight: '权重',
    edit: '编辑',
    viewTheContent: '查看内容',
    nameOnThePhoneEnglish: '客户端名称（英）',
    nameOnThePhoneArabic: '客户端名称（阿）',
    nameOnThePhoneFrench: '客户端名称（法）',
    nameOnThePhoneIn: '客户端名称（印尼）',
    version: '版本号',
    contentCN: '中文内容',
    contentEN: '英文内容',
    contentAR: '阿语内容',
    contentFR: '法语内容',
    contentIN: '印尼内容',
    week: '周',
    youHaveAlreadyAddedThisCategory: '请勿重复添加！',
    link: '链接',
    type: '类型',
    complete: '完成',
    continue: '继续',
    onEmpty: '清空',
    emptySuccessful: '清空成功！',
    fixed: '固定',
    unfixed: '非固定',
    unoccupied: '空闲',
    occupied: '使用中',
    signOut: '退出登录',
    notes: '备注',
    describe: '描述',
    year: '年',
    month: '月',
    day: '日',
    ON_OFF: '上下架',
    ON: '上架',
    OFF: '下架',
    sureToCloseTheRecommendation: '确定要下架吗？',
    closedSuccessfully: '下架成功',
    clickToUpload: '点击上传',
    clickToJump: '点击跳转',
    fileName: '文件名',
    downloadTemplate: '下载模版',
    addToBlacklist: '加入黑名单',
    areYouSureToAddThisPictureToBlacklist: '确定将这张图片加入黑名单？',
    successfullyAddedToTheBlacklist: '加入黑名单成功！',
    uploadTitle:'点击或拖拽图片文件至此区域即可上传',
    uploadContent:'支持单次或批量上传，',
    pinPost: '置顶帖子',
    unPinPost: '取消置顶'
};

export const en = {
    language: 'Language',
    arabicLanguage: 'Arabic',
    englishLanguage: 'English',
    frenchLanguage: 'French',
    inLanguage: 'In',
    middleEast: 'Middle East',
    middleEastMorocco: 'Middle East & Morocco',
    malay: 'Malay',
    indonesia: 'Indonesia',
    arabic: 'Arabic',
    malayLanguage: 'Malay',
    indonesian: 'Indonesian',
    englishTitle: 'English Title',
    arabicTitle: 'Arabic Title',
    frenchTitle: 'French Title',
    InTitle: 'In Title',
    userId: 'UserID',
    translate: 'Translate',
    okText: 'OK',
    confirm: 'Confirm',
    cancelText: 'Cancel',
    add: 'Add',
    newlyAdded: 'Newly Added',
    modify: 'Modify',
    create: 'Create',
    closeText: 'Close',
    Ignore: 'Ignore',
    areYouSureIgnore: 'Are You Sure Ignore?',
    loadingText: 'Loading...',
    delText: 'Delete',
    areYouSureDelete: 'Are You Sure Delete?',
    resetText: 'Reset',
    searchText: 'Search',
    queryText: 'Search',
    pleaseInput: 'Please Enter',
    pleaseChoose: 'Please Choose',
    pleaseAdd: 'Please Add',
    back: 'Back',
    number: 'Number',
    numbers: 'Number',
    userID: 'UserID',
    content: 'Content',
    time: 'Time',
    createTime: 'Create Time',
    operation: 'Operation',
    handle: 'Operation',
    comments: 'Comments',
    viewComments: 'View Comments',
    done: 'Done',
    unDone: 'Undone',
    operator: 'Operator',
    selectTime: 'Select time',
    pleaseSelectTime: 'Please Select time',
    redoSuccessful: 'Refresh Successful!',
    operationSuccessful: 'Operation Successful!',
    newlyAddedSuccessful: 'Newly Added Successful!',
    saveSuccessful: 'Save Successful!',
    createSuccessful: 'Create Successful!',
    modifySuccessful: 'Modify Successful!',
    deleteSuccessful: 'Delete Successful!',
    updateSuccessful: 'Update Successful!',
    dislodgeSuccessful: 'Dislodge Successful!',
    update: 'Update',
    save: 'Save',
    dislodge: 'Dislodge',
    nextPage: 'Next Page',
    previousPage: 'Previous Page',
    noMicroBlog: 'No MicroBlog',
    noComment: 'No Comment',
    pleaseInputUserId: 'Please input UserID',
    pleaseInputUserType: 'Please input User Type',
    total: 'Total',
    items: 'Items',
    filter: 'Filter',
    yes: 'Yes',
    no: 'No',
    exportExcel: 'Export Excel',
    valid: 'Valid',
    invalid: 'Invalid',
    state: 'State',
    uploadImage: 'Upload Image',
    image: 'Image',
    post: 'Post ',
    upload: 'Upload...',
    coverPicture: 'Cover picture',
    coverImage: 'Cover Image',
    pleaseUploadCoverImage: 'Please upload cover image',
    empty: '(Empty)',
    deleteReason: 'Delete Reason',
    startingTime: 'Starting Time',
    endTime: 'End Time',
    operatingTime: 'Operating Time',
    deleteTime: 'Delete Time',
    expirationTime: 'EndTime',
    all: 'All',
    lastModifiedBy: 'Operator',
    lastModifiedTime:'Operator Date',
    name: 'name',
    weight: 'Weight',
    edit: 'Edit',
    viewTheContent: 'View the Content',
    nameOnThePhoneEnglish: 'Name on the phone(English)',
    nameOnThePhoneArabic: 'Name on the phone(Arabic)',
    nameOnThePhoneFrench: 'Name on the phone(French)',
    nameOnThePhoneIn: 'Name on the phone(In)',
    version: 'Version',
    contentCN: 'Content (CN)',
    contentEN: 'Content (EN)',
    contentAR: 'Content (AR)',
    contentFR: 'Content (FR)',
    contentIN: 'Content (IN)',
    week: 'Week',
    youHaveAlreadyAddedThisCategory: 'You have already added this Category.',
    link: 'Link',
    type: 'Type',
    complete: 'Complete',
    continue: 'Continue',
    onEmpty: 'Empty',
    emptySuccessful: 'Empty Successful!',
    fixed: 'Fixed',
    unfixed: 'Unfixed',
    unoccupied: 'Unoccupied',
    occupied: 'Occupied',
    signOut: 'Sign out',
    notes: 'Notes',
    describe: 'Describe',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    ON_OFF: 'ON/OFF',
    ON: 'ON',
    OFF: 'OFF',
    sureToCloseTheRecommendation: 'Sure to close the recommendation?',
    closedSuccessfully: 'Closed successfully',
    clickToJump: 'click to jump',
    clickToUpload: 'Click to upload',
    fileName: 'file name',
    downloadTemplate: 'Download Template',
    addToBlacklist: 'add to blacklist',
    areYouSureToAddThisPictureToBlacklist: 'Are you sure to add this picture to blacklist?',
    successfullyAddedToTheBlacklist: 'Successfully added to the blacklist!',
    uploadTitle:'Click or drag image file to this area to upload',
    uploadContent:'Support for a single or bulk upload,',
    pinPost: '置顶帖子',
    unPinPost: '取消置顶'
};

export const ar = {
    ...en,
    arabicLanguage: 'عربى',
    pinPost: '置顶帖子',
    unPinPost: '取消置顶'
};
