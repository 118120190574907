const userAgent = navigator.userAgent;

export const isWeiXin = /MicroMessenger/i.test(userAgent); // 微信
export const isQQ = 0 <= userAgent.toLowerCase().replace('mqqbrowser', '').indexOf('qq');
export const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; // android终端
export const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
export const isMac = /macintosh|mac os x/i.test(navigator.userAgent); // 是否为mac系统（包含iphone手机）

/**
 * @function 判断是否移动端
 * */
export const isMobile = (isMobile = undefined): boolean => {
    if (isMobile === undefined) {
        return !!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    }
    return isMobile;
};
