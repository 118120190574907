export const zh = {
    role: '角色',
    roleName: '角色名',
    newRole: '新增角色',
    editRole: '编辑角色',
    noRole: '无角色',
    generalUserRoleManage: '普通用户角色管理',
    generalUser: '普通用户',
};
export const en = {
    role: 'Role',
    roleName: 'Role Name',
    newRole: 'New Role',
    editRole: 'Edit Role',
    noRole: 'No Role',
    generalUserRoleManage: 'General User Role Manage',
    generalUser: 'General User',
};

export const ar = {
    role: 'Role',
    roleName: 'Role Name',
    newRole: 'New Role',
    editRole: 'Edit Role',
    noRole: 'No Role',
    generalUserRoleManage: 'General User Role Manage',
    generalUser: 'General User',
}
