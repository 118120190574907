import { i18n } from './setupI18n';
import { useBaseStore } from '@/store/modules/base';
import { setStorageItem } from '@/utils/StorageManager';

/**
 * @function 设置语言
 * */
export function handleSetLanguage(language) {
    const baseStore = useBaseStore();
    baseStore.language = language;
    (i18n.global.locale as any).value = language;
    document.querySelector('html')?.setAttribute('lang', language);
    setStorageItem('language', language);
}

/**
 * @function 判断浏览器语言
 * */
export function browserLanguage(boolean) {
    if (boolean) return null;
    let language = navigator.language.toLowerCase();
    if (language.includes('zh')) language = 'zh';
    if (language.includes('en')) language = 'en';
    if (language.includes('ar')) language = 'ar';
    return language;
}
