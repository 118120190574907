<template>
    <div v-show="props.show" class="full-screen-loading" :style="STYLE.FULL_SCREEN_LOADING">
        <img :style="STYLE.LOADING" :src="RESOURCES.LOADING" alt="Wocute" />
    </div>
</template>

<script setup lang="ts">
    import LOADING from '@/assets/images/common/loading.webp';
    import { computed, reactive } from 'vue';

    interface PropsTs {
        show: boolean;
        background?: string;
        size?: string;
    }
    const props = withDefaults(defineProps<PropsTs>(), {
        show: false,
        background: '#2d3a4a',
        size: '230px',
    });

    const RESOURCES = reactive({
        LOADING: computed(() => LOADING),
    });

    const STYLE = reactive({
        FULL_SCREEN_LOADING: computed(() => ({ background: props.background })),
        LOADING: computed(() => ({ width: props.size, height: props.size })),
    });
</script>

<style scoped>
    .full-screen-loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
