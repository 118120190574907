/**
 * @export 基础主路由
 * @author 林良伟
 * @Created 2022/5/23.
 */
export const main = {
    '/': {
        path: '/',
        name: 'Index',
        component: () => import('@/pages/base/home/index.vue'),
    },
};

export const otherRoutes = {
    noAuth: {
        path: '/no-auth',
        name: 'NoAuth',
        component: () => import('@/pages/base/error/no-auth/index.vue'), // 没有权限
    },
    errorAll: {
        path: '/:pathMatch(.*)*',
        name: 'ErrorAll',
        component: () => import('@/pages/base/error/404/index.vue'),
    },
};

export const noLayouts = {
    login: {
        path: '/login',
        name: 'Login',
        component: () => import('@/pages/base/login/index.vue'),
        meta: { requireAuth: false },
    },
};
