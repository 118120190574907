export const zh = {
    createNewPush: '创建新推送',
    pushTaskList: '推送任务列表',
    pushStatus: '推送状态',
    pushId: '推送id',
    pushImage: '推送图片',
    pushTime: '推送时间',
    pushing: '正在推送',
    pushStartTime: '推送开始时间',
    pushEndTime: '推送结束时间',
    pushType: '推送类型',
    pushTitle: '推送标题',
    pushContent: '推送内容',
    pleaseSelectPushType: '请选择推送类型',
    pleaseInputPushTitle: '请输入推送标题',
    pleaseSelectTime: '请输入时间',
    pleaseInputPushContent: '请输入推送内容',
    pushed: '已推送',
    unPushed: '待推送',
    deleted: '已删除',
    expired: '已过期',
    founder: '创建人',
    microBlogPushList: '帖子推送列表',
    topicPushList: '话题推送列表',
    forceUpdate: '是否强更',
    androidNotificationList: 'Android 推送列表',
    iOSNotificationList: 'iOS 推送列表',
    image: '图片',
    vote: '投票',
    imageOrVote: '图片或投票',
    poster: '发帖人',
    pleaseSelectPoster: '请选择发帖人！',
    options: '选项',
    required: '必填',
    unSelected: '未选择',
    use: '使用',
    pleaseInputMicroBlogContent: '请输入帖子内容！',
    pleaseImproveVoteContent: '请完善投票内容！',
    pleaseUploadImage: '请上传图片！',
    noPregnancyModePush: '{0}年{1}月{2}日，孕期模式无推荐内容。',
    noMenstrualModePush: '{0}年{1}月{2}日，经期模式无推荐内容。',
    noPregnancyPreparingModePush: '{0}年{1}月{2}日，备孕模式无推荐内容。',
    unDisposed: '待办（{0}）',
    excelDataCannotExceed20000rows: 'excel数据不能超过2万行！',
    menstruation: '经期',
    getPregnant: '备孕',
    pregnancy: '孕期',
    activeUser30Day: '活跃用户（30天内）',
    newUser24Hours: '新用户（24小时内）',
    designatedUser: '指定用户',
    ACTIVE_1_DAY: '活跃用户（24小时内）',
    ACTIVE_3_DAY: '活跃用户（3天内）',
    ACTIVE_7_DAY: '活跃用户（7天内）',
    selectUser: '用户群体',
    userMode: '用户模式',
    userArea: '用户区域',
    area: '区域',
    arTitleAndContentCannotBeEmpty: '推送内容（阿语）和推送标题（阿语）不能为空。',
    malayTitleAndContentCannotBeEmpty: '推送内容（马来语）和推送标题（马来语）不能为空。',
    inTitleAndContentCannotBeEmpty: '推送内容（印尼语）和推送标题（印尼语）不能为空。',
    clickToUploadEXCEL: '点击上传EXCEL',
    loginDoesNotExist: 'login不存在',
    repeatLogin: '重复login',
    pleaseImproveThePushTitleAndContent: '请完善推送标题及内容！',
};
export const en = {
    arTitleAndContentCannotBeEmpty: 'Push Content(Arabic) and Push Title(Arabic) cannot be empty.',
    malayTitleAndContentCannotBeEmpty: 'Push Content(Malay) and Push Title(Malay) cannot be empty.',
    inTitleAndContentCannotBeEmpty: 'Push Content(Indonesian) and Push Title(Indonesian) cannot be empty.',
    userMode: 'User mode',
    getPregnant: 'Get pregnant',
    pregnancy: 'Pregnancy',
    createNewPush: 'Create New Push',
    pushTaskList: 'Push Task List',
    pushStatus: 'Push Status',
    pushId: 'Push id',
    pushImage: 'Push Image',
    pushTime: 'Push Time',
    pushing: 'Pushing',
    pushStartTime: 'Push End Time',
    pushEndTime: 'Push End Time',
    pushType: 'Push Type',
    pushTitle: 'Push Title',
    pushContent: 'Push Content',
    pleaseSelectPushType: 'Please Select Push Type',
    pleaseInputPushTitle: 'Please Input Push Title',
    pleaseSelectTime: 'Please Select Time',
    pleaseInputPushContent: 'Please Input Push Content',
    pushed: 'Pushed',
    unPushed: 'UnPushed',
    deleted: 'Deleted',
    expired: 'Expired',
    founder: 'Founder',
    microBlogPushList: 'MicroBlog PushList',
    topicPushList: 'Topic PushList',
    forceUpdate: 'Force Update',
    androidNotificationList: 'Android Notification List',
    iOSNotificationList: 'iOS Notification List',
    image: 'Image',
    vote: 'Vote',
    imageOrVote: 'Image or Vote',
    poster: 'Poster',
    pleaseSelectPoster: 'please Select Poster!',
    options: 'Options',
    required: 'Required',
    unSelected: 'UnSelected',
    use: 'Use',
    pleaseInputMicroBlogContent: 'Please Input MicroBlog Content!',
    pleaseImproveVoteContent: 'Please Improve Vote Content!',
    pleaseUploadImage: 'Please Upload Image！',
    noPregnancyModePush: '{0}year{1}month{2}day, there is no recommended for pregnancy mode',
    noMenstrualModePush: '{0}year{1}month{2}day, there is no recommended for menstrual mode',
    noPregnancyPreparingModePush: '{0}year{1}month{2}day, there is no recommended for pregnancy-preparing mode',
    unDisposed: 'Undisposed（{0}）',
    excelDataCannotExceed20000rows: 'Excel data cannot exceed 20,000 rows!',
    menstruation: 'Menstruation',
    activeUser30Day: 'Active user(within 30 days)',
    newUser24Hours: 'New user(in 24 hours)',
    designatedUser: 'Designated user',
    ACTIVE_1_DAY: 'Active user(in 24 hours)',
    ACTIVE_3_DAY: 'Active user(within 3 days)',
    ACTIVE_7_DAY: 'Active user(within 7 days)',
    selectUser: 'Select user',
    userArea: 'User area',
    area: 'Area',
    clickToUploadEXCEL: 'Click to upload EXCEL',
    loginDoesNotExist: 'login does not exist',
    repeatLogin: 'Repeat login',
    pleaseImproveThePushTitleAndContent: 'Please improve the push title and content!',
};
