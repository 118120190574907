export const zh = {
    index: '首页',
    userReportPost: '用户举报帖子',
    warmthPostTop: 'Warmth 帖子置顶',
    setWhiteList: '指定白名单列表',
    userProhibitions: '用户禁言处理',
    webForm: 'web论坛',
    newPostList: 'New 帖子列表',
    hotPostList: 'Hot 帖子列表',
    microBlog: '帖子',
    hashtag: '话题',
    topicManage: '话题管理',
    insightsArticle: 'Insights 文章',
    newArticle: '新文章',
    articleList: '文章列表',
    deletedPosts: '已删除帖子',
    hotContentPush: '热门内容推送',
    contentClassify: '内容分类',
    poolList: '内容池',
    categoryList: '主分类',
    inAppUpdateNotifications: '版本升级推送管理',
    iOSNotificationList: 'iOS 推送列表',
    androidNotificationList: 'Android 推送列表',
    postManagement: '帖子管理',
    helpContentManagement: 'Help 内容管理',
    helpCategory: 'Help 分类',
    insightsContentManagement: 'Insights 内容管理',
    pushManagement: 'Push 管理',
    userManagement: '用户管理',
    pregnancyHomePage: '孕期首页',
    pregnancyHomePage_NewArticle: '孕期首页-新文章',
    pregnancyHomePage_ArticleList: '孕期首页-文章列表',
    articlePush: '文章推送',
    postsPush: '帖子推送',
    topicPush: '话题推送',
    pageConfigure: '页面配置',
    hotManagement: 'Hot 内容运营',
    positionSetup: '运营位设置',
    hottedList: '运营位已展示列表',
    sectionManagement: '版块管理',
    contentSections: '内容板块',
    recommendedContent: '板块内容推荐',
    newPost: '新帖子',
    publishNewPost: '发布新帖子',
    sectionsContentManagement: 'Sections 内容管理',
    contentAudit: '内容审核',
    postReviewPage: '审核页面',
    taskPage: '工单页面',
    contentConfigurationForToolPage: '工具页内容推荐',
    pregnancyMode: '孕期模式',
    menstrualMode: '经期模式',
    pregnancyPreparingMode: '备孕模式',
    newMicroBlog: '新帖子',
    pendingMicroBlogList: '待发布帖子列表',
    generalUserRoleManage: '普通用户角色管理',
    postRecommendation: '板块发帖引导',
    eventManagement: '活动管理',
    eventPostManagement: '活动帖子管理',
    eventSupport: '活动支持',
    promotion: '推广位',
    nicknameSensitiveWords: '昵称敏感词管理',
    CommunityOperation:'社区运营位推荐'
};
export const en = {
    index: 'Home',
    userReportPost: 'Report Management',
    warmthPostTop: 'Warmth Top Microblog',
    setWhiteList: 'Set WhiteList',
    userProhibitions: 'Silent User',
    webForm: 'Moments',
    newPostList: 'New Post List',
    hotPostList: 'Hot Post List',
    microBlog: 'Microblog',
    hashtag: 'Hashtag',
    topicManage: 'Topic Management',
    insightsArticle: 'Insights Article',
    newArticle: 'New Article',
    articleList: 'Article List',
    deletedPosts: 'Deleted Posts',
    hotContentPush: 'Hot Content Push',
    contentClassify: 'Content Management',
    poolList: 'Content Pool',
    categoryList: 'Content Category',
    inAppUpdateNotifications: 'In-app Update Notifications',
    iOSNotificationList: 'iOS Notification List',
    androidNotificationList: 'Android Notification List',
    postManagement: 'Post Management',
    helpContentManagement: 'Help Content Management',
    helpCategory: 'Help Category',
    insightsContentManagement: 'Insights Content Management',
    pushManagement: 'Push Management',
    userManagement: 'User management',
    pregnancyHomePage: 'Pregnancy Homepage',
    pregnancyHomePage_NewArticle: 'Pregnancy Homepage - New Article',
    pregnancyHomePage_ArticleList: 'Pregnancy Homepage - Article List',
    articlePush: 'Article Push',
    postsPush: 'Posts Push',
    topicPush: 'Pregnancy Homepage - Topic Push',
    pageConfigure: 'Page Configure',
    hotManagement: 'Hot Management',
    positionSetup: 'Position Setup',
    hottedList: 'Hotted List',
    sectionManagement: 'Section Management',
    contentSections: 'Content Sections',
    recommendedContent: 'Recommended Content',
    newPost: 'New Post',
    publishNewPost: 'Publish New Post',
    sectionsContentManagement: 'Sections Content Management',
    contentAudit: 'Content Audit',
    postReviewPage: 'Post Review Page',
    taskPage: 'Task Page',
    contentConfigurationForToolPage: 'Content Configuration for Tool Page',
    pregnancyMode: 'Pregnancy Mode',
    menstrualMode: 'Menstrual Mode',
    pregnancyPreparingMode: 'Pregnancy-preparing Mode',
    newMicroBlog: 'New MicroBlog',
    pendingMicroBlogList: 'Pending MicroBlog List',
    generalUserRoleManage: 'Common User Role Manage',
    postRecommendation: 'Post Recommendation',
    eventManagement: 'Event Management',
    eventPostManagement: 'Event Post Management',
    eventSupport: 'Event support',
    promotion: 'Promotion',
    nicknameSensitiveWords: 'Nickname Sensitive Words',
    CommunityOperation:'Community operation position recommendation'

};
export const ar = {
    ...en,
    contentAudit: 'مراجعة المحتوى',
    postReviewPage: 'مراجعة الصفحة',
    taskPage: 'صفحة المهمة',
};
