import type { App, Plugin } from 'vue';
import { isObject } from './is';
import { isMac } from '@/utils/BrowserModel';

export const withInstall = <T>(component: T, alias?: string) => {
    const comp = component as any;
    comp.install = (app: App) => {
        const appParams = app;
        // @ts-ignore
        appParams.component(comp.name || comp.displayName, component);
        if (alias) {
            appParams.config.globalProperties[alias] = component;
        }
    };
    return component as T & Plugin;
};

export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
    const srcParams = src;
    let key: string;
    for (key in target) {
        srcParams[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (srcParams[key] = target[key]);
    }
    return srcParams;
}

/**
 * 下载文件
 * @function downloadFileUrl
 * @param url 文件地址
 */
export function downloadFileUrl(url: string) {
    const downloadElement = document.createElement('a');
    downloadElement.href = url;
    document.body.appendChild(downloadElement);
    downloadElement.click(); // 点击下载
    document.body.removeChild(downloadElement); // 下载完成移除元素
}

/**
 * 将字符串按规则转数组
 * @date 2020-10-23
 * @string {string} 原字符串
 * @returns {array} 转换的数组
 */
export function stringToArray(string) {
    const splitReg = new RegExp(/,|，|、|;|；/); // 切割规则
    const noStr = string.replace(/[\r\n]/g, '').replace(/[\r\n\s+]/g, ''); // 去回车换行、空格
    return noStr.split(splitReg).filter(v => v !== ''); // 切割并过滤空值
}

/**
 * 文章类型转后缀
 */
export function fileTyeToSuffix(type) {
    console.log('🚀 ~ file: index.ts:56 ~ fileTyeToSuffix ~ type:', type);
    const typeParams = type?.toLocaleLowerCase?.();
    if (typeParams.indexOf('jpg') > -1 || typeParams.indexOf('jpeg') > -1) {
        return 'jpeg';
    } else if (typeParams.indexOf('png') > -1) {
        return 'png';
    } else if (typeParams.indexOf('gif') > -1) {
        return 'gif';
    } else if (typeParams.indexOf('webp') > -1) {
        return 'webp';
    } else {
        return typeParams;
    }
}

/**
 * @function 文件大小 b 转 kb
 * */
export function sizeBToKb(size) {
    const specs = isMac ? 1000 : 1024;
    return Math.ceil(size / specs) + 'KB';
}

/**
 * @function 跳转谷歌翻译
 * @content 需要翻译的内容
 * */
export function goToGoogleTranslation(content) {
    let contentParams = content;
    contentParams = contentParams.replace('#', '');
    const link = `https://translate.google.com/?sl=auto&tl=zh-CN&text=${contentParams}&op=translate`;
    window.open(link, '_blank');
}

/**
 * 将字符串按规则转数组
 * @string {string} 原字符串
 */
export function stringCommaToArray(string) {
    const splitReg = new RegExp(/,|，|、|;|；/); // 切割规则
    const noString = string.replace(/[\r\n]/g, '').replace(/[\r\n\s+]/g, ''); // 去回车换行、空格
    return noString.split(splitReg).filter(v => v !== ''); // 切割并过滤空值
}

/**
 * @function 查找重复的一维数组
 * */
export function repeatOneArray(list) {
    const temp = [] as string[];
    list.forEach(item => {
        if (list.indexOf(item) != list.lastIndexOf(item) && temp.indexOf(item) == -1) {
            temp.push(item);
        }
    });
    return temp;
}


export function ObjTurnArr(obj){
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc.push({ key, value });
        return acc;
      }, [] as Array<{key:string,value:unknown}>);
}

/**
 * 去除对象内多余的空值key
 * @function removeNullKey 去除对象内多余的空值key
 * @param {object} object 需要操作的对象
 */
export const removeNullKey = (object = {}) => {
    const _obj = JSON.parse(JSON.stringify(object))
    for (const key in _obj) {
        if (['', null, undefined].indexOf(_obj[key]) !== -1) {
            delete _obj[key]
        }
    }
    return _obj
}