/**
 * @export 版本管理
 * @author 林良伟
 * @Created 2022/6/20.
 */
export default {
    versionManageIos: {
        // ios 列表
        path: '/version-manage/ios',
        name: 'versionManageIos',
        component: () => import('@/pages/modules/version-manage/ios/index.vue'),
    },
    versionManageAndroid: {
        // android 列表
        path: '/version-manage/android',
        name: 'versionManageAndroid',
        component: () => import('@/pages/modules/version-manage/android/index.vue'),
    },
    versionManageOssUpload:{
        // uploadImgFile
        path: '/version-manage/uploadImgFile',
        name: 'versionManageOssUpload',
        component: () => import('@/pages/modules/version-manage/uploadImgFile/index.vue'),
    },
    versionManageLocalCache:{
        path: '/version-manage/local-cache',
        name: 'versionManageLocalCache',
        component: () => import('@/pages/modules/version-manage/localCache/index.vue'),
    },
};
