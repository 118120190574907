import { defineStore } from 'pinia';
import { store } from '../index';
import { handleSetLanguage } from '../../lang/helper';
import { setToken, getToken, removeToken, setStorageItem, getStorageItem } from '@/utils/StorageManager';

const initUserInfo = {
    id: '',
    login: '',
    firstName: 'Wocute',
    email: '',
    verifyEmail: false,
    activated: true, // 是否激活
    langKey: '', // 语言
    createdBy: '',
    createdDate: '',
    lastModifiedBy: '',
    lastModifiedDate: '',
    authorities: [], // 角色集
    userSource: '', // 用户来源
};

export const baseStore = defineStore('baseStore', {
    state: () => ({
        pageTitle: 'Wocute', // 页面标题
        language: getStorageItem('language') || 'en', // 语言
        isRtl: false, // 是否翻转
        userInfo: getStorageItem('userInfo', 'local', true) || initUserInfo,
        token: getToken(), // tokenType + accessToken
        tableLoading: false, // 表单是否正在加载中
    }),
    getters: {
        getTitle: state => state.pageTitle,
        getLanguage: state => state.language,
        getToken: state => state.token,
        getUserInfo: state => state.userInfo,
        getTableLoading: state => state.tableLoading,
    },
    actions: {
        setTitle(title) {
            document.title = title;
            this.pageTitle = title;
        },
        setLanguage(language) {
            handleSetLanguage(language);
        },
        setToken(token) {
            this.token = token;
            setToken(token);
        },
        setUserInfo(userInfo) {
            this.userInfo = userInfo;
            setStorageItem('userInfo', userInfo);
        },
        setRemoveToken() {
            this.token = '';
            removeToken();
        },
        setRemoveUserInfo() {
            this.userInfo = initUserInfo;
            removeToken();
        },
        setTableLoading(isLoading) {
            this.tableLoading = isLoading;
        },
    },
});

export function useBaseStore() {
    return baseStore(store);
}
