export const zh = {
    newMicroBlog: '新帖子',
    pendingMicroBlogList: '待发布帖子列表',
    toIpcId: '话题ID',
    microBlogId: '帖子ID',
    contentId: '内容ID',
    postTime: '发帖时间',
    microBlogImage: '帖子图片',
    sectionsImage: '板块图片',
    microBlogContent: '帖子内容',
    pleaseInputMicroBlogId: '请输入帖子ID',
    topicEndTime: '置顶结束时间',
    creatTopMicroBlog: '创建置顶帖子',
    topMicroBlog: '置顶帖子',
    areYouSureDeleteThisMicroBlog: '确定删除该帖子？',
    areYouSureDislodgeThisMicroBlog: '确定去除该帖子？',
    nonTopMicroBlog: '非置顶帖子',
    published: '已发布',
    unPublished: '不发布',
    unPublish: '未发布',
    publish: '发布',
    publishSuccessful: '发布成功！',
    toIpcName: '话题名称',
    topIcId: '话题ID',
    dynamic: '动态',
    microBlog: '动态数据',
    top: '置顶',
    poolList: '内容池列表',
    poolId: '内容池 ID',
    sectionsId: '板块 ID',
    sections: '板块',
    createSections: '创建板块',
    PoolName: '内容池名称',
    sectionsName: '板块名称',
    categoryWeight: '主分类及权重',
    creatContentPool: '创建内容池',
    editContentPool: '编辑内容池',
    editSections: '编辑板块',
    addCategory: '添加主分类',
    contentPoolName: '内容池主分类',
    categoryName: '主分类名称',
    categoryNames: '分类名称',
    categoryList: '主分类列表',
    categoryLists: '分类列表',
    addContentCategory: '创建主分类',
    categoryId: '主分类ID',
    categoryIds: '分类ID',
    createCategory: '创建分类',
    editCategory: '编辑分类',
    areYouSurToDeleteThisCategory: '确定删除该分类？',
    relevanceSuccessful: '关联成功！',
    deleteMicroBlog: '删帖',
    topSuccessful: '置顶成功！',
    cancelTop: '取消置顶',
    cancelTopSuccessful: '取消置顶成功！',
    xxxComments: '{0} 个评论',
    xxxCLikes: '{0} 个赞',
    xxxVotes: '{0} 人已投票',
    anonymousOrNot: '是否匿名',
    officialAccount: '官方用户',
    normalAccount: '普通白名单用户',
    officialKOL: '人设号',
    userType: '用户类型',
    publishStatus: '发布状态',
    realNameTopic: '非匿名话题',
    anonymousTopic: '匿名话题',
    topicType: '话题类型',
    anonymous: '匿名',
    realName: '非匿名',
    cSideDisplay: '客户端显示',
    pleaseFillTheContentsOfCSideDisplay: '请完善客户端显示内容！',
    sectionManagement: '版块管理',
    postRecommendation: '板块发帖引导',
    topicNameEn: '标签文案（英文）',
    topicNameFr: '标签文案（法语）',
    topicNameAr: '标签文案（阿语）',
    descriptionEn: '引导语（英文）',
    descriptionFr: '引导语（法语）',
    descriptionAr: '引导语（阿语）',
    selectASection: '选择板块',
    topicName: '标签文案',
    description: '引导语',
    chooseATopic: '选择话题',
    feature: '是否精选',
    allPosts: '全部',
    featuredPostsOnly: '精选',
    nonFeaturedPost: '非精选',
    countTheNumberOfPostsThatMeetTheConditions: '查看满足当前条件的帖子总数',
    likes: '点赞数',
    comments: '评论数',
    featureTimeOperator: '精选时间/操作人',
    operateTimeOperator: '操作时间/操作人',
    totalScore: '排序分',
    operatingScore: '运营分',
    features: '设为精选',
    areYouSureToMakeItFeatured: '确认将它设为精选？',
    onceOperateWithCaution: '精选操作不可撤回，请谨慎操作',
    cancel: '取消',
    sure: '确认',
    addScore: '加分',
    minusScore: '减分',
    numberOfPosts: 'number of posts',
    createTime: '发帖时间',
    featureTime: '精选时间',
    eastThirdDistrict: '东三区',
    nextPage: '下一页',
    currentScore: '当前分',
    pleaseEnterTheSortScoreToIncrease: '请输入要增加的排序分',
    pleaseEnterTheSortScoreToReduce: '请输入要减少的排序分',
    promotionStatus: '推广状态',
    promotionChannels: '推广渠道',
    jump: '跳转',
    promotionalMaterial: '推广素材',
    duration: '时长',
    targetedPromotion: '定向推广',
    androidVersionOnLowerThan: '安卓版本不低于',
    theIOSVersionIsNotLowerThan: 'iOS版本不低于',
    promotionStartTime: '推广开始时间',
    promotionEndTime: '推广结束时间',
    numberOfSingleUserPromotions: '单个用户推广次数',
    promoting: '正在推广',
    toBePromoted: '待推广',
    terminated: '已停用',
    expired: '已过期',
    disabled: '停用',
    splashScreen: '闪屏',
    popUps: '弹窗',
    allUsers: '全部用户',
    areYouSureYouWantToDisableThisPush: '确定要停用该推送？',
    areYouSureYouWantToDeleteThisPush: '确定要删除该推送？',
    createNewPush: '创建新推送',
    promotionTime: '推广时间',
    pleaseUploadPromotionalMaterials: '请上传推广素材！',
    supportMaterialFormat: '支持素材格式',
    pleaseUpload_750_1624_10: '需上传750*1624尺寸，小于10M的素材',
    materialPlaybackTimeSeconds: '素材播放时长（秒）',
    handleTopicUpdate: '修改帖子话题',
    handlePraiseNumber: '加赞数',
    handleAddComments: '加评论',
    uploadVideo: '上传视频',
    postedBy: '请选择发帖人',
    postsStatus: '帖子状态',
    publisher: '发布人',
    postsId: '帖子ID',
    viewComments: '查看评论',
    addComments: '增加评论',
    addLikes: '增加点赞',
    modifyTopic: '修改话题',
    unpublishPost: '不发布',
    publishPost: '发布',
    confirmToPublishPost: '确认发布该帖子？',
    confirmToUnPublishPost: '确认取消发布该帖子？',
    replyComments: '回复评论',
    reply: '评论内容: ',
    replyText: '请输入回复评论内容',
    replyFromUser: '回复用户: ',
    selectReplyFromUser: '请选择回复用户',
    replyTextRules: '回复内容不能为空',
    replyFromUserRules: '请选择评论回复用户',
    nickName: '发布人昵称',
    publishPosts: '发布成功',
    unPublishPosts: '取消发布成功',
    topicUpdateSuccess: '修改成功',
    postCount: '帖子数量',
    delTopic: '删除话题',
    operatePostsScoreText: '帖子加减分',
    delTopicModalText: '你确认要移除该帖子的话题？',
    postScoreText: '帖子排序分',
    currentScoreText: '当前得分',
    scoreText: '加减分',
    scoreInputText: '帖子加减分数不能为空',
    addScoreResultText: '成功',

};
export const en = {
    newMicroBlog: 'New MicroBlog',
    pendingMicroBlogList: 'Pending MicroBlog List',
    toIpcId: 'Toipc ID',
    microBlogId: 'MicroblogID',
    contentId: 'ContentId',
    postTime: 'PostTime',
    microBlogImage: 'MicroBlog Image',
    sectionsImage: 'Sections Image',
    microBlogContent: 'Content',
    endTime: 'EndTime',
    pleaseInputMicroBlogId: 'Please input MicroblogID',
    topicEndTime: 'TopEndTime',
    creatTopMicroBlog: 'Create Top Microblog',
    topMicroBlog: 'Top Microblog',
    areYouSureDeleteThisMicroBlog: 'Are you sure delete this microblog?',
    areYouSureDislodgeThisMicroBlog: 'Are you sure dislodge this microblog?',
    nonTopMicroBlog: 'Non-top microblog',
    published: 'Published',
    unPublished: 'UnPublished',
    unPublish: 'UnPublished',
    publish: 'Publish',
    publishSuccessful: 'Publish Successful！',
    toIpcName: 'Toipc Name',
    topIcId: 'Topic ID',
    dynamic: 'Dynamic',
    microBlog: 'Moments',
    top: 'Top',
    poolList: 'Pool List',
    poolId: 'Pool ID',
    sectionsId: 'Sections ID',
    sections: 'Sections',
    createSections: 'Create Sections',
    PoolName: 'Pool Name',
    sectionsName: 'Sections Name',
    categoryWeight: 'Category&Weight',
    creatContentPool: 'Create Content Pool',
    editContentPool: 'Edit Content Pool',
    editSections: 'Edit Sections',
    addCategory: 'Add Category',
    contentPoolName: 'Content Pool Name',
    categoryName: 'Category Name',
    categoryNames: 'Category Name',
    categoryList: 'Category List',
    categoryLists: 'Category List',
    addContentCategory: 'Add Content Category',
    categoryId: 'Category Id',
    categoryIds: 'Category Id',
    createCategory: 'Create Category',
    editCategory: 'Edit Category',
    areYouSurToDeleteThisCategory: 'Are you sure to delete this Category?',
    relevanceSuccessful: 'Relevance Successful!',
    deleteMicroBlog: 'Delete MicroBlog',
    topSuccessful: 'Top Successful!',
    cancelTop: 'Cancel Top',
    cancelTopSuccessful: 'Cancel Top Successful!',
    xxxComments: '{0} Comments',
    xxxCLikes: '{0} Likes',
    xxxVotes: '{0} Votes',
    anonymousOrNot: 'Anonymous or not',
    officialAccount: 'Official Account',
    normalAccount: 'Normal Account',
    officialKOL: 'Official KOL',
    userType: 'User Type',
    publishStatus: 'Publish Status',
    realNameTopic: 'Real-Name Topic',
    anonymousTopic: 'Anonymous Topic',
    topicType: 'Topic Type',
    anonymous: 'Anonymous',
    realName: 'Real-Name',
    cSideDisplay: 'C-Side Display',
    pleaseFillTheContentsOfCSideDisplay: 'Please fill the contents of C-Side Display!',
    sectionManagement: 'Section Management',
    postRecommendation: 'Post Recommendation',
    topicNameEn: 'Topic Name（en）',
    topicNameFr: 'Topic Name（fr）',
    topicNameAr: 'Topic Name（ar）',
    descriptionEn: 'Description（en）',
    descriptionFr: 'Description（fr）',
    descriptionAr: 'Description（ar）',
    selectASection: 'Select a section',
    topicName: 'Topic Name',
    description: 'Description',
    chooseATopic: 'Choose a topic',
    feature: 'Feature',
    allPosts: 'All posts',
    featuredPostsOnly: 'Featured posts only',
    nonFeaturedPost: 'Non-featured post',
    countTheNumberOfPostsThatMeetTheConditions: 'Count the number of posts that meet the conditions',
    likes: 'Likes',
    comments: 'Comments',
    featureTimeOperator: 'Feature Time/Operator',
    operateTimeOperator: 'operate Time/Operator',
    totalScore: 'Total Score',
    operatingScore: 'Operating Score',
    features: 'Feature',
    areYouSureToMakeItFeatured: 'Are you sure to make it featured?',
    onceOperateWithCaution: 'Once selected, it cannot be withdrawn, please operate with caution!',
    cancel: 'Cancel',
    sure: 'Sure',
    addScore: 'Add Score',
    minusScore: 'Minus Score',
    numberOfPosts: 'number of posts',
    createTime: 'Create Time',
    featureTime: 'Feature Time',
    eastThirdDistrict: 'East Third District',
    nextPage: 'Next Page',
    currentScore: 'Current Score',
    pleaseEnterTheSortScoreToIncrease: 'Please enter the sort score to increase',
    pleaseEnterTheSortScoreToReduce: 'Please enter the sort score to reduce',
    materialPlaybackTimeSeconds: 'Material playback time (seconds)',
    pleaseUpload_750_1624_10: 'It is necessary to upload materials with a size of 750*1624 and less than 10M',
    supportMaterialFormat: 'Support material format',
    pleaseUploadPromotionalMaterials: 'Please upload promotional materials!',
    promotionTime: 'Promotion time',
    createNewPush: 'Create New Push',
    areYouSureYouWantToDisableThisPush: 'Are you sure you want to disable this push?',
    areYouSureYouWantToDeleteThisPush: 'Are you sure you want to delete this push?',
    allUsers: 'All users',
    splashScreen: 'Splash screen',
    popUps: 'Pop-ups',
    disabled: 'disabled',
    promoting: 'Promoting',
    toBePromoted: 'To be promoted',
    expired: 'Expired',
    terminated: 'Terminated',
    numberOfSingleUserPromotions: 'Number of Single User Promotions',
    promotionEndTime: 'Promotion end time',
    promotionStartTime: 'Promotion start time',
    theIOSVersionIsNotLowerThan: 'The iOS version is not lower than',
    androidVersionOnLowerThan: 'Android version on lower than',
    targetedPromotion: 'Targeted Promotion',
    duration: 'duration',
    promotionalMaterial: 'Promotional material',
    jump: 'Jump',
    promotionChannels: 'Promotion channels',
    promotionStatus: 'Promotion Status',
    handleTopicUpdate: 'Modify Topic',
    handlePraiseNumber: 'Add Likes',
    handleAddComments: 'Add Comments',
    uploadVideo: 'Upload Video',
    postedBy: 'Please choose a publisher',
    postsStatus: 'Status',
    publisher: 'Login ID',
    postsId: 'Post ID',
    viewComments: 'View Comments',
    addComments: 'Add Comment',
    addLikes: 'Add Likes',
    modifyTopic: 'Modify Topic',
    unpublishPost: 'Unpublish Post',
    publishPost: 'Publish Post',
    confirmToPublishPost: 'Want to unpublish this post?',
    confirmToUnPublishPost: 'Publish this post？',
    replyComments: 'Reply the comment',
    reply: 'replyText: ',
    replyText: 'Please enter the text',
    replyFromUser: 'Reply from: ',
    selectReplyFromUser: 'Select it',
    replyTextRules: 'The reply content cannot be empty',
    replyFromUserRules: 'Please select the comment reply user',
    nickName: 'Nick name',
    publishPosts: 'Your post is now live.',
    unPublishPosts: 'Unpublished successfully.',
    topicUpdateSuccess: 'Changes saved.',
    postCount: 'Post count',
    delTopic: 'Remove Topic',
    operatePostsScoreText: 'Posts Score',
    delTopicModalText: 'Remove the topic for this post？',
    postScoreText: 'Score Management',
    currentScoreText: 'Current Score',
    scoreText: 'Score',
    scoreInputText: 'Post score cannot be empty',
    addScoreResultText: 'Score Updated Successfully.',
};

export const ar = {
    newMicroBlog: 'New MicroBlog',
    pendingMicroBlogList: 'Pending MicroBlog List',
    toIpcId: 'Toipc ID',
    microBlogId: 'MicroblogID',
    contentId: 'ContentId',
    postTime: 'PostTime',
    microBlogImage: 'MicroBlog Image',
    sectionsImage: 'Sections Image',
    microBlogContent: 'Content',
    endTime: 'EndTime',
    pleaseInputMicroBlogId: 'Please input MicroblogID',
    topicEndTime: 'TopEndTime',
    creatTopMicroBlog: 'Create Top Microblog',
    topMicroBlog: 'Top Microblog',
    areYouSureDeleteThisMicroBlog: 'Are you sure delete this microblog?',
    areYouSureDislodgeThisMicroBlog: 'Are you sure dislodge this microblog?',
    nonTopMicroBlog: 'Non-top microblog',
    published: 'Published',
    unPublished: 'UnPublished',
    unPublish: 'UnPublished',
    publish: 'Publish',
    publishSuccessful: 'Publish Successful！',
    toIpcName: 'Toipc Name',
    topIcId: 'Topic ID',
    dynamic: 'Dynamic',
    microBlog: 'Moments',
    top: 'Top',
    poolList: 'Pool List',
    poolId: 'Pool ID',
    sectionsId: 'Sections ID',
    sections: 'Sections',
    createSections: 'Create Sections',
    PoolName: 'Pool Name',
    sectionsName: 'Sections Name',
    categoryWeight: 'Category&Weight',
    creatContentPool: 'Create Content Pool',
    editContentPool: 'Edit Content Pool',
    editSections: 'Edit Sections',
    addCategory: 'Add Category',
    contentPoolName: 'Content Pool Name',
    categoryName: 'Category Name',
    categoryNames: 'Category Name',
    categoryList: 'Category List',
    categoryLists: 'Category List',
    addContentCategory: 'Add Content Category',
    categoryId: 'Category Id',
    categoryIds: 'Category Id',
    createCategory: 'Create Category',
    editCategory: 'Edit Category',
    areYouSurToDeleteThisCategory: 'Are you sure to delete this Category?',
    relevanceSuccessful: 'Relevance Successful!',
    deleteMicroBlog: 'Delete MicroBlog',
    topSuccessful: 'Top Successful!',
    cancelTop: 'Cancel Top',
    cancelTopSuccessful: 'Cancel Top Successful!',
    xxxComments: '{0} Comments',
    xxxCLikes: '{0} Likes',
    xxxVotes: '{0} Votes',
    anonymousOrNot: 'Anonymous or not',
    officialAccount: 'Official Account',
    normalAccount: 'Normal Account',
    officialKOL: 'Official KOL',
    userType: 'User Type',
    publishStatus: 'Publish Status',
    realNameTopic: 'Real-Name Topic',
    anonymousTopic: 'Anonymous Topic',
    topicType: 'Topic Type',
    anonymous: 'Anonymous',
    realName: 'Real-Name',
    cSideDisplay: 'C-Side Display',
    pleaseFillTheContentsOfCSideDisplay: 'Please fill the contents of C-Side Display!',
    sectionManagement: 'Section Management',
    postRecommendation: 'Post Recommendation',
    topicNameEn: 'Topic Name（en）',
    topicNameFr: 'Topic Name（fr）',
    topicNameAr: 'Topic Name（ar）',
    descriptionEn: 'Description（en）',
    descriptionFr: 'Description（fr）',
    descriptionAr: 'Description（ar）',
    selectASection: 'Select a section',
    topicName: 'Topic Name',
    description: 'Description',
    chooseATopic: 'Choose a topic',
    feature: 'Feature',
    allPosts: 'All posts',
    featuredPostsOnly: 'Featured posts only',
    nonFeaturedPost: 'Non-featured post',
    countTheNumberOfPostsThatMeetTheConditions: 'Count the number of posts that meet the conditions',
    likes: 'Likes',
    comments: 'Comments',
    featureTimeOperator: 'Feature Time/Operator',
    operateTimeOperator: 'operate Time/Operator',
    totalScore: 'Total Score',
    operatingScore: 'Operating Score',
    features: 'Feature',
    areYouSureToMakeItFeatured: 'Are you sure to make it featured?',
    onceOperateWithCaution: 'Once selected, it cannot be withdrawn, please operate with caution!',
    cancel: 'Cancel',
    sure: 'Sure',
    addScore: 'Add Score',
    minusScore: 'Minus Score',
    numberOfPosts: 'number of posts',
    createTime: 'Create Time',
    featureTime: 'Feature Time',
    eastThirdDistrict: 'East Third District',
    nextPage: 'Next Page',
    currentScore: 'Current Score',
    pleaseEnterTheSortScoreToIncrease: 'Please enter the sort score to increase',
    pleaseEnterTheSortScoreToReduce: 'Please enter the sort score to reduce',
    materialPlaybackTimeSeconds: 'Material playback time (seconds)',
    pleaseUpload_750_1624_10: 'It is necessary to upload materials with a size of 750*1624 and less than 10M',
    supportMaterialFormat: 'Support material format',
    pleaseUploadPromotionalMaterials: 'Please upload promotional materials!',
    promotionTime: 'Promotion time',
    createNewPush: 'Create New Push',
    areYouSureYouWantToDisableThisPush: 'Are you sure you want to disable this push?',
    areYouSureYouWantToDeleteThisPush: 'Are you sure you want to delete this push?',
    allUsers: 'All users',
    splashScreen: 'Splash screen',
    popUps: 'Pop-ups',
    disabled: 'disabled',
    promoting: 'Promoting',
    toBePromoted: 'To be promoted',
    expired: 'Expired',
    terminated: 'Terminated',
    numberOfSingleUserPromotions: 'Number of Single User Promotions',
    promotionEndTime: 'Promotion end time',
    promotionStartTime: 'Promotion start time',
    theIOSVersionIsNotLowerThan: 'The iOS version is not lower than',
    androidVersionOnLowerThan: 'Android version on lower than',
    targetedPromotion: 'Targeted Promotion',
    duration: 'duration',
    promotionalMaterial: 'Promotional material',
    jump: 'Jump',
    promotionChannels: 'Promotion channels',
    promotionStatus: 'Promotion Status',
    handleTopicUpdate: 'تغيير موضوع المنشور',
    handlePraiseNumber: 'إضافة الإعجاب ',
    handleAddComments: ' إضافة التعليقات',
    uploadVideo: 'نشر الفيديو',
    postedBy: 'الرجاء اختيار الناشر',
    postsStatus: 'Status',
    publisher: 'Login ID',
    postsId: 'Post ID',
    viewComments: 'View Comments',
    addComments: 'Add Comment',
    addLikes: 'Add Likes',
    modifyTopic: 'Modify Topic',
    unpublishPost: 'Unpublish Post',
    publishPost: 'Publish Post',
    confirmToPublishPost: 'Publish this post？',
    confirmToUnPublishPost: 'Want to unpublish this post?',
    replyComments: 'Reply the comment',
    reply: 'replyText: ',
    replyText: 'Please enter the text',
    replyFromUser: 'Reply from: ',
    selectReplyFromUser: 'Select it',
    replyTextRules: 'The reply content cannot be empty',
    replyFromUserRules: 'Please select the comment reply user',
    nickName: 'Nick name',
    publishPosts: 'Your post is now live.',
    unPublishPosts: 'Unpublished successfully.',
    topicUpdateSuccess: 'Changes saved.',
    postCount: 'Post count',
    delTopic: 'Remove Topic',
    operatePostsScoreText: 'Posts Score',
    delTopicModalText: 'Remove the topic for this post？',
    postScoreText: 'Score Management',
    currentScoreText: 'Current Score',
    scoreText: 'Score',
    scoreInputText: 'Post score cannot be empty',
    addScoreResultText: 'Score Updated Successfully.',
}
