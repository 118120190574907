const toString = Object.prototype.toString;

export function is(val: unknown, type: string) {
    return toString.call(val) === `[object ${type}]`;
}

export function isDef<T = unknown>(val?: T): val is T {
    return typeof val !== 'undefined';
}

export function isUnDef<T = unknown>(val?: T): val is T {
    return !isDef(val);
}

export function isObject(val: any): val is Record<any, any> {
    return val !== null && is(val, 'Object');
}

export function isEmpty<T = unknown>(val: T): val is T {
    if (isArray(val) || isString(val)) {
        return val.length === 0;
    }

    if (val instanceof Map || val instanceof Set) {
        return val.size === 0;
    }

    if (isObject(val)) {
        return Object.keys(val).length === 0;
    }

    return false;
}

export function isDate(val: unknown): val is Date {
    return is(val, 'Date');
}

export function isNull(val: unknown): val is null {
    return val === null;
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
    return isUnDef(val) && isNull(val);
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
    return isUnDef(val) || isNull(val);
}

export function isNumber(val: unknown): val is number {
    return is(val, 'Number');
}

// export function isPromise<T = any>(val: unknown): val is Promise<T> {
//     return is(val, 'Promise') && isObject(val) && isFunction(val.then) && isFunction(val.catch);
// }
//
// export function isFunction(val: unknown): val is Function {
//     return typeof val === 'function';
// }

export function isString(val: unknown): val is string {
    return is(val, 'String');
}

export function isBoolean(val: unknown): val is boolean {
    return is(val, 'Boolean');
}

export function isRegExp(val: unknown): val is RegExp {
    return is(val, 'RegExp');
}

export function isArray(val: any): val is Array<any> {
    return val && Array.isArray(val);
}

export function isWindow(val: any): val is Window {
    return typeof window !== 'undefined' && is(val, 'Window');
}

export function isElement(val: unknown): val is Element {
    return isObject(val) && !!val.tagName;
}

export function isMap(val: unknown): val is Map<any, any> {
    return is(val, 'Map');
}

export const isServer = typeof window === 'undefined';

export const isClient = !isServer;

export function isUrl(path: string): boolean {
    const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
    return reg.test(path);
}

export function isImg(name: string): boolean {
    const nameParams = name?.toLocaleLowerCase();
    const reg = RegExp(/\.png|\.jpg|\.jpeg|\.bmp|\.psd|\.svg|\.tiff|\.gif|\.webp/);
    return reg.test(nameParams);
}

// 是否带中文
export function isHaveChinese(name: string): boolean {
    const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
    return reg.test(name);
}

export function isImage(suffix) {
    const suffixParams = suffix?.toLocaleLowerCase();
    return ['png', 'jpg', 'jpeg', 'bmp', 'svg', 'tiff', 'webp', 'gif'].includes(suffixParams);
}
