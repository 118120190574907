// 请求失败处理
import { message } from 'ant-design-vue';
import { CreateAxiosOptions } from '@/http/axios/types';
import { loginExitInit } from '@/router';
import { useBaseStore } from '@/store/modules/base';

const baseStore = useBaseStore();

export default function requestFailed(error) {
    const errorConfig: CreateAxiosOptions = error.config;
    const errorData = error.data;
    const code = (errorData && errorData.status) || (error.request && error.request.status) || (error.response && error.response.status) || error.code || '';
    const errorMessage = error.title || error.message || (errorData && (errorData.title || errorData.message)) || (error.response && error.response.data && error.response.data.title) || 'Error';

    errorConfig?.requestOptions?.tableLoading !== false && baseStore.setTableLoading(false);

    console.group(`请求错误，返回码:${code}`);
    console.error('错误信息:', errorMessage);
    console.groupEnd();

    if (code === 401) {
        message.error('登陆失效，请重新登陆！').then();
        loginExitInit();
    } else if (errorConfig?.requestOptions?.showErrorMessage !== false) {
        message.error(errorMessage).then();
    }

    return Promise.reject({
        code,
        message: errorMessage,
        error,
    });
}
