/**
 * @export 用户管理
 * @author 林良伟
 * @Created 2022/11/3.
 */
export default {
    userManageSetWhiteList: {
        // 指定白名单列表
        path: '/user-manage/set-white-list',
        name: 'userManageSetWhiteList',
        component: () => import('@/pages/modules/user-manage/set-white-list/index.vue'),
    },
    userManageRoleManage: {
        // 普通用户角色管理
        path: '/user-manage/role-manage',
        name: 'userManageRoleManage',
        component: () => import('@/pages/modules/user-manage/role-manage/index.vue'),
    },
    userManageUserProhibitions: {
        // 用户禁言处理
        path: '/user-manage/user-prohibitions',
        name: 'userManageUserProhibitions',
        component: () => import('@/pages/modules/user-manage/user-prohibitions/index.vue'),
    },
    userManageSystemTaboo: {
        // 系统禁言处理
        path: '/user-manage/system-taboo',
        name: 'userManageSystemTaboo',
        component: () => import('@/pages/modules/user-manage/system-taboo/index.vue'),
    },
    userManageGrievanceHandling: {
        // 申诉处理
        path: '/user-manage/grievance-handling',
        name: 'userManageGrievanceHandling',
        component: () => import('@/pages/modules/user-manage/grievance-handling/index.vue'),
    },
    userManageUserList: {
        // 用户列表
        path: '/user-manage/user-list',
        name: 'userManageUserList',
        component: () => import('@/pages/modules/user-manage/user-list/index.vue'),
    },
    userManageSensitiveWordManage: {
        // 昵称敏感词管理
        path: '/user-manage/sensitive-word-manage',
        name: 'userManageSensitiveWordManage',
        component: () => import('@/pages/modules/user-manage/sensitive-word-manage/index.vue'),
    },
};
