/**
 * @export 勋章管理
 * @author 林良伟
 * @Created 2023/2/14.
 */
export default {
    medalManageMedalConfig: {
        // 勋章配置
        path: '/medal-manage/medal-config',
        name: 'medalManageMedalConfig',
        component: () => import('@/pages/modules/medal-manage/medal-config/index.vue'),
    },
    headFrameConfig: {
        path: '/medal-manage/head-frame-config',
        name: 'headFrameConfig',
        component: () => import('@/pages/modules/medal-manage/head-frame-config/index.vue'),
    }
};
