/**
 * @export 活动管理
 * @author 林良伟
 * @Created 2023/2/14.
 */
export default {
    activityManageActivityPostsManage: {
        // 活动帖子管理
        path: '/activity-manage/activity-posts-manage',
        name: 'activityManageActivityPostsManage',
        component: () => import('@/pages/modules/activity-manage/activity-posts-manage/index.vue'),
    },
};
