export const zh = {
    topic: '话题',
    topicUnder: '下的话题',
    selectTopic: '选择话题',
    pleaseSelectTopic: '请选择话题！',
    topicNotes: '话题备注',
    topicCategory: '话题分类',
    allTopic: '全部话题',
    hotTopic: '热门话题',
    creatTopic: '创建话题',
    editTopic: '修改话题',
    topicName: '话题名称',
    topicSuggestion: '话题建议',
    inputTopicIDOrTopicName: '输入话题ID或话题名称',
    topicDescription: '话题说明',
    validOrNot: '话题是否有效',
    endTime: '热门话题结束时间',
    rank: '热门话题排序',
    weightinessOfHotTopic: '热门话题权重',
    topicID: '话题ID',
    pleaseInputTopicID: '请输入话题ID',
    microBlogNumber: '帖子数量（热度）',
    status: '话题状态',
    hotOrNot: '是否热门话题',
    pleaseChoose: '请选择时间',
    category: '归类到主分类',
    selectCategory: '选择主分类',
    contentManagement: '内容管理',
    sectionsBrowse: '按板块浏览',
    topicsForEvents: '活动用话题',
    activitiesUseTopicsToCountPoints: '活动用话题',
};
export const en = {
    topic: 'Topic',
    topicUnder: 'Topic Under',
    selectTopic: 'Select Category',
    pleaseSelectTopic: 'Please Select Topic!',
    topicNotes: 'Topic Notes',
    topicCategory: 'Topic Category',
    allTopic: 'All',
    hotTopic: 'Hot',
    creatTopic: 'Create Topic',
    editTopic: 'Edit Topic',
    topicName: 'TopicName',
    topicSuggestion: 'Topic Suggestions',
    inputTopicIDOrTopicName: 'Input TopicID or TopicName',
    topicDescription: 'TopicDescription',
    validOrNot: 'Valid or not',
    endTime: 'EndTime',
    rank: 'Rank',
    weightinessOfHotTopic: 'Weightiness of Hot Topic',
    topicID: 'TopicID',
    pleaseInputTopicID: 'Please Input Topic ID',
    microBlogNumber: 'MicroblogNumber',
    status: 'Status',
    hotOrNot: 'Hot or not?',
    pleaseChoose: 'Please choose time',
    category: 'Category',
    selectCategory: 'Select Category',
    contentManagement: 'Content Management',
    sectionsBrowse: 'Sections Browse',
    topicsForEvents: 'Topics for events',
    activitiesUseTopicsToCountPoints: 'Activities use topics to count points',
};
