/**
 * @export help 内容管理
 * @author 林良伟
 * @Created 2022/8/26.
 */
export default {
    helpManageNewArticle: {
        // help 新文章
        path: '/help-manage/new-article',
        name: 'helpManageNewArticle',
        component: () => import('@/pages/modules/help-manage/new-article/index.vue'),
    },
    helpManageArticleList: {
        // help 文章列表
        path: '/help-manage/article-list',
        name: 'helpManageArticleList',
        component: () => import('@/pages/modules/help-manage/article-list/index.vue'),
    },
    helpManageClassify: {
        // 分类管理
        path: '/help-manage/classify',
        name: 'helpManageClassify',
        component: () => import('@/pages/modules/help-manage/classify/index.vue'),
    }
};
