/**
 * @export 打标内容管理
 * @author 林良伟
 * @Created 2022/12/4.
 */
export default {
    markingContentManageMarking: {
        // 内容打标
        path: '/marking-content-manage/marking',
        name: 'markingContentManageMarking',
        component: () => import('@/pages/modules/marking-content-manage/pages/marking/index.vue'),
        meta: { pageLang: ['zh', 'en', 'ar'] },
    },
    markingContentManageLabelWorkOrder: {
        // 标签工单
        path: '/marking-content-manage/label-work-order',
        name: 'markingContentManageLabelWorkOrder',
        component: () => import('@/pages/modules/marking-content-manage/pages/label-work-order/index.vue'),
        meta: { pageLang: ['zh', 'en', 'ar'] },
    },
    markingContentManageUserQuery: {
        // 用户查询
        path: '/marking-content-manage/user-query',
        name: 'markingContentManageUserQuery',
        component: () => import('@/pages/modules/marking-content-manage/pages/user-query/index.vue'),
        meta: { pageLang: ['zh', 'en', 'ar'] },
    },
};
