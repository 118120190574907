/**
 * @export 任务系统
 * @author 林良伟
 * @Created 2023/2/14.
 */
export default {
    taskSystemTaskConditions: {
        // 条件任务
        path: '/task-system/task-conditions',
        name: 'taskSystemTaskConditions',
        component: () => import('@/pages/modules/task-system/task-conditions/index.vue'),
    },
};
