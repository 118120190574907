export const zh = {
    damagePlatformInterests: '损害平台利益',
    pornography: '重色情',
    sexy: '轻色情',
    religious: '涉政宗教',
    violence: '暴恐',
    vulgar: '低俗',
    infringement: '侵权',
    underage: '未成年',
    violation: '违规',
    other: '其它',
    pleaseFillInTheRemarks: '请填写备注',
    pleaseUploadEvidence: '请填上传证据',
    microBlogId: '帖子ID',
    userID: '用户ID',
    source: '来源',
    content: '内容',
    postTime: '发帖时间',
    processingTime: '处理时间',
    category: '标签',
    status: '状态',
    machineReviewStatus: '机审状态',
    operator: '操作人',
    operate: '操作',
    post: '帖子',
    comment: '评论',
    sVideo: '短视频',
    notProcessed: '未处理',
    processed: '已处理',
    startDate: '开始日期',
    endDate: '结束日期',
    userPostID: '用户/帖子ID',
    all: '全部',
    automaticBlocking: '机审拦截',
    pass: '通过',
    delete: '删除',
    search: '查询',
    ignore: '忽略',
    manualReviewPassed: '人工审核通过',
    confirm: '确认',
    cancel: '取消',
    violationContentHasBeenDeleted: '该内容涉及违规已被管理员删除，请重新编辑',
    taskType: '工单类型',
    audit: '审核',
    report: '举报',
    taskID: '工单ID',
    pendingTask: '待处理工单',
    successful: '操作成功！',
    machineReviewPassed: '机审通过',
    userTaskID: '用户/工单ID',
    advertisement: '广告',
    clearAvatar: '清除头像',
    clearName: '清除名称',
    clearProfile: '清除简介',
    punish: '处罚',
    notReviewed: '未审核',
    contentTagManagment: '内容打标管理',
    contentTag: '内容打标',
    startTagging: '开始打标',
    untagged: '未打标',
    tagList: '标签列表',
    selectedTag: '已选标签',
    submitAndConfirm: '提交并确认',
    submitAndClose: '提交并结束',
    confirmSubmission: '确认提交',
    confirmModification: '确认修改',
    level1: '一级标签',
    level2: '二级标签',
    edit: '编辑',
    topic: '话题',
    pictures: '配图',
    text: '文字',
    type: '类型',
    article: '文章',
    vote: '投票',
    pleaseEnter: '请输入',
    resetOptions: '重置选项',
    details: '详情',
    tagQuery: '标签查询',
    userQuery: '用户查询',
    usersNickname: '用户昵称',
    avatar: '头像',
    sign: '签名',
    tagRatio: '标签比例',
    contentManagement: '内容管理',
    emptyState2: '空',
    confirmTips: '确定',
    theTaskHasBeenReturnedToTheTaskPool: '工单已返回工单池',
    createTime: '创建时间',
    qualityLabel: '质量标签',
    uncategorized: '未分类',
    highQuality: '优质',
    lowQuality: '低质',
    contentQualityLabel: '内容质量标签',
    contentQuality: '内容质量',
    auditPrompt_1: '投票贴不走内容审核逻辑， 所以机审通过会自动生成标签工单流转到内容打标。',
    auditPrompt_2: '图文贴有内容审核， 所以机审通过后操作人工审核通过才生成标签工单流转到内容打标。',
    picture: '图片',
};

export const en = {
    damagePlatformInterests: 'damagePlatformInterests',
    pornography: 'Pornography',
    sexy: 'Sexy',
    religious: 'Religious',
    violence: 'Violence',
    vulgar: 'Vulgar',
    infringement: 'Infringement',
    underage: 'Underage',
    violation: 'Violation',
    other: 'Other',
    pleaseFillInTheRemarks: 'Please fill in the remarks',
    pleaseUploadEvidence: 'Please upload evidence',
    microBlogId: 'Micro Blog Management',
    userID: 'User ID',
    source: 'Source',
    content: 'Content',
    postTime: 'Post time',
    processingTime: 'Processing time',
    category: 'Category',
    status: 'Status',
    machineReviewStatus: 'Machine review status',
    operator: 'Operator',
    operate: 'Operate',
    post: 'Post',
    comment: 'Comment',
    notProcessed: 'Not processed',
    processed: 'Processed',
    startDate: 'Start date',
    endDate: 'End date',
    userPostID: 'User/Post ID',
    all: 'All',
    automaticBlocking: 'Automatic blocking',
    pass: 'Pass',
    delete: 'Delete',
    search: 'Search',
    ignore: 'Ignore',
    manualReviewPassed: 'Manual review passed',
    confirm: 'Confirm',
    cancel: 'Cancel',
    violationContentHasBeenDeleted: 'The content involving violations has been deleted by the administrator, please re-edit',
    taskType: 'Task Type',
    audit: 'Audit',
    report: 'Report',
    taskID: 'TaskID',
    pendingTask: 'Pending Task',
    successful: 'Successful',
    machineReviewPassed: 'Machine review passed',
    userTaskID: 'User/TaskID',
    advertisement: 'Advertisement',
    clearAvatar: 'Clear avatar',
    clearName: 'Clear Name',
    clearProfile: 'Clear Profile',
    punish: 'Punish',
    notReviewed: 'Not Reviewed',
    contentTagManagment: 'Content Tag Managment',
    contentTag: 'Content Tag',
    startTagging: 'Content Tag',
    untagged: 'Untagged',
    tagList: 'Tag List',
    selectedTag: 'Selected Tag',
    submitAndConfirm: 'Submit and Confirm',
    submitAndClose: 'submit and close',
    confirmSubmission: 'Confirm Submission',
    confirmModification: 'Confirm Modification',
    level1: 'Level 1',
    level2: 'Level 2',
    edit: 'Edit',
    topic: 'Topic',
    pictures: 'Pictures',
    text: 'Text',
    type: 'Type',
    article: 'Article',
    vote: 'Vote',
    pleaseEnter: 'Please Enter',
    resetOptions: 'Reset Options',
    details: 'Details',
    tagQuery: 'Tag Query',
    userQuery: 'User Query',
    usersNickname: "User's Nickname",
    avatar: 'Avatar',
    sign: 'Sign',
    tagRatio: 'Tag Ratio',
    contentManagement: 'Content Management',
    emptyState2: 'Empty',
    confirmTips: 'Confirm',
    theTaskHasBeenReturnedToTheTaskPool: 'The Task has been returned to the task pool',
    createTime: 'Create Time',
    qualityLabel: 'Quality label',
    uncategorized: 'Uncategorized',
    highQuality: 'High quality',
    lowQuality: 'Low quality',
    contentQualityLabel: 'Content Quality Label',
    contentQuality: 'content quality',
    auditPrompt_1: 'Voting posts do not follow the content review logic, so if the machine review passes, a label work order will be automatically generated and transferred to content labeling.',
    auditPrompt_2:
        'Graphics and texts are subject to content review, so after the machine review is passed, the operation manual review is passed to generate a label work order and flow to the content marking.',
    picture: 'Picture',
};
export const ar = {
    damagePlatformInterests: 'إضرار بمصالح المنصة',
    pornography: 'إباحية',
    sexy: 'جنسي',
    religious: 'دينى',
    violence: 'عنف',
    vulgar: 'فاحش',
    infringement: 'مخالفة',
    underage: 'تحت السن',
    violation: 'انتهاك',
    other: 'غير ذلك',
    pleaseFillInTheRemarks: 'يرجى ملء الملاحظات',
    pleaseUploadEvidence: 'يرجى تحميل الدليل',
    microBlogId: 'إدارة المدونة الصغيرة',
    userID: 'معرِّف المستخدم',
    source: 'المصدر',
    content: 'المحتوى',
    postTime: 'وقت النشر',
    processingTime: 'وقت المعالجة',
    category: 'الفئة',
    status: 'الحالة',
    machineReviewStatus: 'حالة مراجعة الجهاز',
    operator: 'المشغّل',
    operate: 'تشغيل',
    post: 'منشور',
    comment: 'تعليق',
    notProcessed: 'لم تتم المعالجة',
    processed: 'تمت المعالجة',
    startDate: 'تاريخ البدء',
    endDate: 'تاريخ الانتهاء',
    userPostID: 'معرِّف المستخدم/المنشور',
    all: 'الكل',
    automaticBlocking: 'الحظر الآلي',
    pass: 'تمرير',
    delete: 'حذف',
    search: 'بحث',
    ignore: 'تجاهل',
    manualReviewPassed: 'نجحت المراجعة اليدوية',
    confirm: 'تأكيد',
    cancel: 'إلغاء',
    violationContentHasBeenDeleted: 'The content involving violations has been deleted by the administrator, please re-edit',
    taskType: 'نوع المهمة',
    audit: 'مراجعة',
    report: 'إبلاغ',
    taskID: 'رقم المهمة',
    pendingTask: 'مهمة معلقة ',
    successful: 'تم بنجاح',
    machineReviewPassed: 'تمت المراجعة الآلية',
    userTaskID: 'رقم المستخدم',
    advertisement: 'إعلانات',
    clearAvatar: 'مسح الصورة الشخصية',
    clearName: 'مسح الإسم',
    clearProfile: 'مسح النبذة التعريفية',
    punish: 'عقاب',
    notReviewed: 'لم تستعرض',
    contentTagManagment: 'إدارة إشارات المحتوى',
    contentTag: 'إشارة المحتوى',
    startTagging: 'بدأ الإشارة',
    untagged: 'إلغاء الإشارة',
    tagList: 'قائمة الإشارات',
    selectedTag: 'اختيار إشارة',
    submitAndConfirm: 'إرسال وتأكيد',
    submitAndClose: 'إرسال وإغلاق',
    confirmSubmission: 'تأكيد الإرسال',
    confirmModification: 'تأكيد التعديل',
    level1: 'مستوى 1',
    level2: 'مستوى 2',
    edit: 'تعديل',
    topic: 'موضوع',
    pictures: 'صور',
    text: 'نص',
    type: 'نوع',
    article: 'مقال',
    vote: 'تصويت',
    pleaseEnter: 'قومى بالإدخال',
    resetOptions: 'خيارات إعادة الضبط',
    details: 'تفاصيل',
    tagQuery: 'الاستعلام عن الإشارة',
    userQuery: 'استعلام المستخدم',
    usersNickname: 'لقب المستخدم',
    avatar: 'الصورة الشخصية ',
    sign: 'توقيع',
    tagRatio: 'نسبة الإشارة',
    contentManagement: 'إدارة المحتوى',
    emptyState2: 'لا شيء',
    confirmTips: 'تأكيد',
    theTaskHasBeenReturnedToTheTaskPool: 'تم إرجاع المهمة لقائمة المهمات',
    createTime: 'وقت الابتكار',
    qualityLabel: 'علامة الجودة',
    uncategorized: 'غير مصنف',
    highQuality: 'جودة عالية',
    lowQuality: 'جودة منخفضة',
    contentQualityLabel: 'تسمية جودة المحتوى',
    contentQuality: 'جودة المحتوى',
    auditPrompt_1: 'لا تتبع منشورات التصويت منطق مراجعة المحتوى ، لذلك إذا نجحت مراجعة الجهاز ، فسيتم إنشاء أمر عمل التسمية تلقائيًا ونقله إلى تصنيف المحتوى.',
    auditPrompt_2: 'تخضع الرسومات والنصوص لمراجعة المحتوى ، لذلك بعد اجتياز مراجعة الجهاز ، يتم تمرير مراجعة دليل التشغيل لإنشاء أمر عمل التسمية والتدفق إلى وضع علامة على المحتوى.',
    picture: 'صورة',
};
