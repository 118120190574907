export const zh = {
    article: '文章',
    articleId: '文章ID',
    category: '主题',
    pleaseSelectATopic: '请选择主题',
    pleaseEnterTheArticleTitle: '请输入文章标题',
    pleaseSelectLanguage: '请选择语言！',
    uploadRecommendedArticlesCover: '上传编辑推荐封面图...',
    recommendThisArticle: '推荐此文章',
    articleLink: '文章链接',
    articleTitle: '文章标题',
    pleaseUploadTheCover: '请上传推荐封面图',
    pleaseInputArticleTitle: '请输入文章标题',
    pleaseInputArticleTitleOrContent: '请输入文章标题或内容',
    pleaseInputArticleContent: '请输入文章内容',
    sureToDeleteThisArticle: '确定删除该文章？',
    contentCategory: '主分类',
    articlePushList: 'Insights 文章推送列表',
    pleaseInputArticleId: '请输入文章ID',
    englishLink: '英文链接',
    arabicLink: '阿语链接',
    frenchLink: '法语链接',
    helpCategory: 'Help 分类',
    homeDisplay: '首页展示',
};
export const en = {
    article: 'Article',
    articleId: 'Article ID',
    category: 'Category',
    pleaseSelectATopic: 'Please select a topic',
    pleaseEnterTheArticleTitle: 'Please enter the article title',
    pleaseSelectLanguage: 'Please select language',
    uploadRecommendedArticlesCover: 'Upload recommended articles Cover ...',
    recommendThisArticle: 'Recommend this article',
    articleLink: 'Article Link',
    articleTitle: 'Article title',
    pleaseUploadTheCover: 'Please upload the recommended cover image',
    pleaseInputArticleTitle: 'Please enter article title',
    pleaseInputArticleTitleOrContent: 'Please enter article title or content',
    pleaseInputArticleContent: 'Please enter the content of the article',
    sureToDeleteThisArticle: 'Sure to delete this article?',
    contentCategory: 'Content Category',
    articlePushList: 'Article PushList',
    pleaseInputArticleId: 'Please input Article ID',
    englishLink: 'English Link',
    arabicLink: 'Arabic Link',
    frenchLink: 'French Link',
    helpCategory: 'Help Category',
    homeDisplay: 'Home Display',
};
