/**
 * @export 权限管理 permissionsManage
 * @author 林良伟
 * @Created 2023/4/4.
 */
export default {
    permissionsManageAccount: {
        // 账号管理
        path: '/permissions-manage/account',
        name: 'permissionsManageAccount',
        component: () => import('@/pages/modules/permissions-manage/account/index.vue'),
    },
    permissionsManageRole: {
        // 角色管理
        path: '/permissions-manage/role',
        name: 'permissionsManageRole',
        component: () => import('@/pages/modules/permissions-manage/role/index.vue'),
    },
    permissionsManageMenu: {
        // 菜单管理
        path: '/permissions-manage/menu',
        name: 'permissionsManageMenu',
        component: () => import('@/pages/modules/permissions-manage/menu/index.vue'),
    },
};
