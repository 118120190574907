import { defineStore } from 'pinia';
import { store } from '../store/index';
import { getStorageItem, removeStorageItem, setStorageItem } from '@/utils/StorageManager';
import { menuItem, menuTs } from '@/layouts/components/Menu/types';
import { tabListItem } from '@/layouts/components/Header/components/Notify/types';
import { allMenuListApi } from '@/layouts/http';
import { allMenuListTs } from '@/layouts/types';

export const layoutsStore = defineStore('layoutsStore', {
    state: () => ({
        miniNav: getStorageItem('miniNav') || 'false',
        menuOpenKeys: getStorageItem('menuOpenKeys', 'session') || [],
        menuSelectedKeys: getStorageItem('menuSelectedKeys', 'session') || [],
        menuConfigList: [] as menuTs[],
        firstLevelMenu: {}, // 一级菜单数据
        secondaryMenu: {}, // 二级菜单数据
        notify: [
            // 右上角铃铛消息数据
            {
                key: 'WAITING',
                name: '', // 待办
                langReplace: undefined, // 翻译占位符替换
                component: {},
                list: [],
            },
        ] as tabListItem[],
        routerLoading: false, // 路由是否加载中
        allMenuList: [] as allMenuListTs[], // 全部菜单列表
        authMenuList: getStorageItem('authMenuList', 'local', true) || ([] as menuItem[]), // 当前可访问的菜单
        userRoutes: getStorageItem('userRoutes', 'local', true) || ([] as menuItem[]),
        // allowAccessPage: [] as string[], // 允许访问的页面
    }),
    getters: {
        getMiniNav: state => JSON.parse(state.miniNav),
        getMenuOpenKeys: state => (typeof state.menuOpenKeys === 'string' && JSON.parse(state.menuOpenKeys)) || state.menuOpenKeys,
        getMenuSelectedKeys: state => (typeof state.menuSelectedKeys === 'string' && JSON.parse(state.menuSelectedKeys)) || state.menuSelectedKeys,
        getMenuConfigList: state => state.menuConfigList,
        getFirstLevelMenu: state => state.firstLevelMenu,
        getSecondaryMenu: state => state.secondaryMenu,
        getNotify: state => state.notify,
        getRouterLoading: state => state.routerLoading,
        getAuthMenuList: state => state.authMenuList,
        getUserRoutes: state => state.userRoutes,
        // getAllowAccessPage: state => state.allowAccessPage,
    },
    actions: {
        setMiniNav(boolean) {
            setStorageItem('miniNav', boolean);
            this.miniNav = boolean;
        },
        setMenuOpenKeys(openKey) {
            this.menuOpenKeys = openKey;
            setStorageItem('menuOpenKeys', openKey, 'session');
        },
        setMenuSelectedKeys(selectedKeys) {
            this.menuSelectedKeys = selectedKeys;
            setStorageItem('menuSelectedKeys', selectedKeys, 'session');
        },
        setRemoveMenuOpenKeys() {
            this.menuOpenKeys = [];
            removeStorageItem('menuOpenKeys', 'session');
        },
        setRemoveMenuSelectedKeys() {
            this.menuSelectedKeys = [];
            removeStorageItem('menuSelectedKeys', 'session');
        },
        setMenuConfigList(menuConfigList) {
            this.menuConfigList = menuConfigList;
        },
        setFirstLevelMenu(menuItem = {}) {
            this.firstLevelMenu = menuItem;
        },
        setSecondaryMenu(menuItem) {
            this.secondaryMenu = menuItem;
        },
        setNotify(data) {
            this.notify = data;
        },
        setRouterLoading(isLoading) {
            this.routerLoading = isLoading;
        },
        setUserRoutes(data) {
            setStorageItem('userRoutes', data);
            this.userRoutes = data;
        },
        setAuthMenuList(data) {
            setStorageItem('authMenuList', data);
            this.authMenuList = data;
        },
        // setAllowAccessPage(data) {
        //     this.allowAccessPage = data;
        // },
        getAllMenuList(refresh = false) {
            return new Promise<allMenuListTs[]>(resolve => {
                if (refresh || !this.allMenuList.length) {
                    allMenuListApi().then(res => {
                        const listData = res.data || [];
                        // 过滤空子级目录
                        function recursionFilter(list) {
                            list.map(item => {
                                const items = item;
                                if (items.childAuths?.length) {
                                    recursionFilter(items.childAuths);
                                } else {
                                    items.childAuths = undefined;
                                }
                            });
                        }
                        recursionFilter(listData);
                        this.allMenuList = listData;
                        resolve(listData);
                    });
                } else {
                    resolve(this.allMenuList);
                }
            });
        },
    },
});

export function useLayoutsStore() {
    return layoutsStore(store);
}
