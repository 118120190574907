/**
 * @export 帖子管理
 * @author 林良伟
 * @Created 2022/6/10.
 */
export default {
    postsManagePublishNewPost: {
        // 发布新帖子
        path: '/posts-manage/publish-new-post',
        name: 'postsManagePublishNewPost',
        component: () => import('@/pages/modules/posts-manage/publish-new-post/index.vue'),
    },
    PublishVideoPost: {
        // 发布新视频帖子
        path: '/posts-manage/publish-videopost',
        name: 'PublishVideoPost',
        component: () => import('@/pages/modules/posts-manage/publish-video-post/index.vue'),
    },
    postsManagePendingPostsList: {
        // 待发布帖子列表
        path: '/posts-manage/pending-posts-list',
        name: 'postsManagePendingPostsList',
        component: () => import('@/pages/modules/posts-manage/pending-posts-list/index.vue'),
    },
    newPostManage: {
        // New帖子管理(新开发的New帖子管理页面)
        path: '/posts-manage/new-post',
        name: 'newPostManage',
        component: () => import('@/pages/modules/posts-manage/new-post-manage/index.vue'),
    },
    postsManageNewPostList: {
        // New帖子列表
        path: '/posts-manage/new-post-list',
        name: 'postsManageNewPostList',
        component: () => import('@/pages/modules/posts-manage/new-post-list/index.vue'),
    },
    postsManageHotPostList: {
        // hot帖子列表
        path: '/posts-manage/hot-post-list',
        name: 'postsManageHotPostList',
        component: () => import('@/pages/modules/posts-manage/hot-post-list/index.vue'),
    },
    postsManageDeletedPosts: {
        // 已删除帖子
        path: '/posts-manage/deleted-posts',
        name: 'postsManageDeletedPosts',
        component: () => import('@/pages/modules/posts-manage/deleted-posts/index.vue'),
    },
    postsManageUserReportPost: {
        // 用户举报帖子
        path: '/posts-manage/user-report-post',
        name: 'postsManageUserReportPost',
        component: () => import('@/pages/modules/posts-manage/user-report-post/index.vue'),
    },
    postsManageTopicManage: {
        // 话题管理
        path: '/posts-manage/topic-manage',
        name: 'postsManageTopicManage',
        component: () => import('@/pages/modules/posts-manage/topic-manage/index.vue'),
    },
    postsManageCommentManage: {
        // 评论管理
        path: '/posts-manage/comment-manage',
        name: 'postsManageCommentManage',
        component: () => import('@/pages/modules/posts-manage/comment-manage/index.vue'),
    },
    postsManageHotPostCard: {
        // hot帖子列表
        path: '/posts-manage/hot-post-card',
        name: 'postsManageHotPostCard',
        component: () => import('@/pages/modules/posts-manage/hot-post-card/index.vue'),
    },
    videoPostsList: {
        // 视频帖子列表
        path: '/posts-manage/video-posts-list',
        name: 'videoPostsList',
        component: () => import('@/pages/modules/posts-manage/video-posts-list/index.vue'),
    }
};
