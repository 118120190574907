/**
 * @export Push 管理
 * @author 林良伟
 * @Created 2022/6/10.
 */
export default {
    pushManageHotContentPush: {
        // 热门帖子推送
        path: '/push-manage/hot-content-push',
        name: 'pushManageHotContentPush',
        component: () => import('@/pages/modules/push-manage/hot-content-push/index.vue'),
    },
};
