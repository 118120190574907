/**
 * @export 全网内容分类
 * @author 林良伟
 * @Created 2022/6/10.
 */
export default {
    contentClassifyContentPool: {
        // 板块管理
        path: '/content-classify/content-pool',
        name: 'contentClassifyContentPool',
        component: () => import('@/pages/modules/content-classify/content-pool/index.vue'),
    },
    contentClassifyMainClassify: {
        // 主分类
        path: '/content-classify/main-classify',
        name: 'contentClassifyMainClassify',
        component: () => import('@/pages/modules/content-classify/main-classify/index.vue'),
    },
    contentClassifyPostingGuidance: {
        // 板块发帖引导
        path: '/content-classify/posting-guidance',
        name: 'contentClassifyPostingGuidance',
        component: () => import('@/pages/modules/content-classify/posting-guidance/index.vue'),
    },
    contentClassifySortByMainCategory: {
        // 主分类排序配置
        path: '/content-classify/sort-by-main-category',
        name: 'contentClassifySortByMainCategory',
        component: () => import('@/pages/modules/content-classify/sort-by-main-category/index.vue'),
    },
};
