/**
 * @export 图片风控
 * @author 林良伟
 * @Created 2023/5/11.
 */
export default {
    imageRiskControlPendingList: {
        // 待处理列表
        path: '/image-risk-control/pending-list',
        name: 'imageRiskControlPendingList',
        component: () => import('@/pages/modules/image-risk-control/pending-list/index.vue'),
    },
    imageRiskControlBlacklistImage: {
        // 黑名单图片
        path: '/image-risk-control/blacklist-image',
        name: 'imageRiskControlBlacklistImage',
        component: () => import('@/pages/modules/image-risk-control/blacklist-image/index.vue'),
    },
};
