/**
 * @export 内容打标
 * @author 方俊杰
 * @Created 2024/8/14.
 */
export default {
    // 文章打标
    tweetsTag: {
        path: '/content-labels/tweets-labels',
        name: 'tweetsTag',
        component: () => import('@/pages/modules/content-labels/tweets-labels/index.vue')
    }
}
