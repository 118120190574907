import { useLayoutsStore } from '@/layouts/store';

let tempSecondaryMenuName;


export function setMenuKey(routePath) {
    const layoutsStore = useLayoutsStore();
    const menuConfig = layoutsStore.getMenuConfigList;
    if (routePath === '/') {
        layoutsStore.setMenuOpenKeys([]);
        return;
    }
    layoutsStore.setMenuSelectedKeys([routePath]);
    layoutsStore.setFirstLevelMenu();
    // menuConfig.forEach((item: menuTs, index) => {
    //     if (item.children) {
    //         const routerItem = item.children.filter(filterItem => filterItem.key === routePath);
    //         const [secondaryMenuName] = menuRoutes.filter(item => item.key === routePath);
    //         if (!secondaryMenuName) return;
    //         if (tempSecondaryMenuName !== secondaryMenuName.desc) {
    //             // 二级菜单
    //             layoutsStore.setSecondaryMenu(secondaryMenuName);
    //         }
    //         tempSecondaryMenuName = secondaryMenuName.desc;
    //         if (routerItem.length) {
    //             // 一级菜单
    //             layoutsStore.setFirstLevelMenu(menuConfig[index]);
    //         }
    //         if (routerItem.length) {
    //             const menuOpenKeys = [menuConfig[index].key];
    //             layoutsStore.setMenuOpenKeys(menuOpenKeys);
    //         }
    //         item.children.forEach(childrenItem => {
    //             if (childrenItem.children?.length) {
    //                 childrenItem.children.forEach(threeChildren => {
    //                     if (threeChildren.key === routePath) {
    //                         layoutsStore.setMenuOpenKeys([item.key, childrenItem.key]);
    //                     }
    //                 });
    //             }
    //         });
    //     }
    // });
}
